
import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the language domain
 */

const selectUserLocation = (state) => state.userLocation || initialState;

/**
 * Select the language locale
 */

const makeSelectUserLocation = () => createSelector(
	selectUserLocation,
	(userLocation) => userLocation.location,
);

export { selectUserLocation, makeSelectUserLocation };
