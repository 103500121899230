import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
	container: {
		width: '100%',
		height: '10rem',
		justifyContent: 'center',
		alignItems: 'center',
	},
	logo: {
		width: '60%',
		height: '50%',
	},
});
