/* eslint-disable no-alert, no-console */
import React, { useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import moment from 'moment';
import Paragraph from '../Content/Paragraph';
import SubTitle from '../Content/SubTitle';
import { t } from '../../services/i18n';
import styles from './styles';
import ToggleBool from '../ToggleBool';
import ModalRead from './ModalRead';
import ImageSecured from '../../containers/ImageSecured';
import API from '../../config/api';

function ChecklistCardRead({ question }) {
	const [modal, setModal] = useState(false);

	return (
		<>
			<View style={[styles.card, question?.deficiency && styles.cardUrgent, question?.NFCValidation && !question?.NFCValidated && styles.cardNoNFC]}>
				{!!question?.deficiency && (
					<View style={styles.urgentPill}>
						<Ionicons name="md-warning" style={styles.urgentIcon} />
						<Text style={styles.urgentText}>{t('Urgent')}</Text>
					</View>
				)}

				{question?.NFCValidation && !question?.NFCValidated && (
					<View style={styles.noNFCPill}>
						<Ionicons name="md-warning" style={styles.urgentIcon} />
						<Text style={styles.urgentText}>{t('TAG NOT SCANNED')}</Text>
					</View>
				)}
				<View style={styles.cardHeader}>
					<Text style={styles.title}>{`Question ${question.i + 1}`}</Text>
					{(question?.responseType === 'yes-no' || question?.responseType === 'confirm') && (
						<ToggleBool read value={JSON.parse(question?.response)} />
					)}
				</View>
				<Text style={styles.subTitle}>{question.name}</Text>
				<Paragraph>{question.description}</Paragraph>
				{question?.pictureOption && question?.picture && (
					<TouchableOpacity onPress={() => setModal(true)}>
						<View accessible style={styles.viewPicture}>
							<Ionicons style={styles.viewPictureIcon} name="ios-image" />
							<Text style={styles.viewPictureText}>{t('View picture')}</Text>
						</View>
					</TouchableOpacity>
				)}
				{question?.responseType === 'text' && (
					<>
						<SubTitle title={t('Explanation')} />
						<Paragraph>
							{question?.response === '' ? 'None' : question?.response ?? 'None'}
						</Paragraph>
					</>
				)}
				<View style={{ flexDirection: "row", marginBottom: 30 }}>
					{
						question?.qPictures && JSON.parse(question?.qPictures)?.map((image) => {
							return (
								<View>
									<ImageSecured
										title={image.title}
										isMax={true}
										progressiveRenderingEnabled
										style={[styles.image]}
										resizeMode="contain"
										id={image.id.toString()?.split("&")[0]}
										source={{ uri: `${API()}/action/getSecureFiles?id=${image.id.toString()?.split("&")[0]}` }}
									/>
									<Text style={{ fontSize: 8, textAlign: "center", marginVertical: 2 }}>{image.title}</Text>
								</View>
							)
						})
					}
				</View>

				{question?.responseType === 'dropdown' && (
					<>
						<SubTitle title={t('Explanation')} />
						<Paragraph>
							{question?.response === '' ? 'None' : question?.response ?? 'None'}
						</Paragraph>
					</>
				)}
				{question?.NFCValidation && (
					<>
						<SubTitle title={`${t('NFC Validated')}: ${question?.NFCValidated ? 'Yes' : 'No'}`} />
						{!question?.NFCValidated && (
							<>
								<SubTitle title={`${t('Reason NFC not scanned')}:`} />
								<Paragraph color="orange">
									{`${question.nfcReason?question.nfcReason:"No reason provided"}`}
								</Paragraph>
							</>
						)}
						<Paragraph>
							{moment(moment.unix(question.date.slice(0, -3))).format('llll')}
						</Paragraph>

					</>
				)}
				{!!question?.deficiency && (
					<>
						<SubTitle title="Urgent Message" />
						<Paragraph urgent>
							{question?.deficiency}
						</Paragraph>
					</>
				)}
			</View>
			{question?.pictureOption && question?.picture && (
				<ModalRead
					isVisible={modal}
					onHide={() => setModal(false)}
					text={question.name}
					imageId={question?.picture}
				/>
			)}
		</>
	);
}

export default ChecklistCardRead;
