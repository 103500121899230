/*
 *
 * LanguageProvider reducer
 *
 */

import produce from 'immer';
import API from '../../config/api';
import request from '../../utils/request';

import { CHANGE_USER_LOCATION, REMOVE_USER_LOCATION } from './constants';

export const initialState = {
	location: [],
};

/* eslint-disable default-case, no-param-reassign */
const languageProviderReducer = (state = initialState, action) => produce(state, (draft) => {
	switch (action.type) {
		case CHANGE_USER_LOCATION:
			draft.location = [];
			draft.location.push(action.location);
			const url = `${API()}/action/geofence?request=add-all`;

			const options = {
				url,
				method: 'POST',
				data: JSON.parse(JSON.stringify(draft.location)),
			};
			request(options).then(({ data }) => {
				// draft.location.splice(0, draft.location.length);
				// state.location=[];
				// if (data) {
				// }
			}).catch(() => {

			})
			break;
		case REMOVE_USER_LOCATION:
			draft.location.splice(0, action.length);
			break;
	}
});

export default languageProviderReducer;
