import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import {
	Alert,
	Text, TextInput, TouchableOpacity, View, ScrollView
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useHistory, useLocation, useParams } from '../../../services/router';
import ButtonHeader from '../../../components/ButtonHeader';
import Content from '../../../components/Content/Content';
import Button from '../../../components/Button';
import styles from './styles';
import { makeSelectButtons } from '../../../providers/ButtonsProvider/selectors';
import { makeSelectLocations } from '../../../providers/LocationsProvider/selectors';
import IconListControl from '../../../components/IconListControl';
import EStyleSheet from 'react-native-extended-stylesheet';
import { t } from '../../../services/i18n';
import API from '../../../config/api';
import request from '../../../utils/request';
import SubTitle from '../../../components/Content/SubTitle';
import { ActivityIndicator } from 'react-native';
import Paragraph from '../../../components/Content/Paragraph';

function DailyShiftReportHistory({ buttons, locations }) {
	const history = useHistory();
	const location = useLocation();
	const [dailyShiftReportData, setDailyShiftReportData] = useState([]);
	const [shiftInfo, setShiftInfo] = useState(null);
	const [date, setDate] = useState(moment().format('YYYY-MM-DD hh:mm a'));
	const [note, setNote] = useState('');
	const [name, setName] = useState('');
	const [shitStarted, setShitStarted] = useState(false);
	const [modal, setModal] = useState(false);
	const [image, setImage] = useState(false);
	const [loader, setLoader] = useState(false);
	const [isResume, setIsResume] = useState(false);
	const { locationId, buttonId } = useParams();
	const currentLocation = locations.find(({ id }) => id === locationId);
	const currentButton = buttons.find((button) => button.id === buttonId);

	// useEffect(async () => {
	// 	const MyDailyShiftReportData = await AsyncStorage.getItem('dailyShiftReportData');
	// 	if (MyDailyShiftReportData) {
	// 		//console.log(JSON.parse(MyDailyShiftReportData));
	// 		setDailyShiftReportData(JSON.parse(MyDailyShiftReportData));
	// 	}
	// }, []);

	useEffect(() => {
		const fetchShiftInfo = async () => {
		  try {
			const url = `${API()}/action/shift?request=shift-info&responseType=json`;
	  
			const options = {
			  url,
			  method: "GET",
			};
	  
			const { data } = await request(options);
			// console.log(data, "datagetShiftInfo");
	  
			if (data) {
			  setShiftInfo(data);
			  setIsResume(data.shiftType === "start");
			}
	  
			await getlist(data);
		  } catch (error) {
			console.error('Error fetching shift info:', error);
		  }
		};
	  
		fetchShiftInfo();
	  }, []);
	  

	const getlist = async (shiftInfo) => {
		setLoader(true);
		let url = `${API()}/action/shift?request=shift-info-all&locationId=${locationId}&shiftType=start&responseType=json`;

		let options = {
			url,
			method: 'GET',
		};

		//console.log(shiftInfo, "options2");
		const { data } = await request(options);
		//console.log(data, "data", data.length);
		if (data && data.length) {
			const shifts = data.filter((shift) => { return shift.id != shiftInfo?.id }).reduce((shifts, game) => {
				const date = game.createdAt.split('T')[0];
				if (!shifts[date]) {
					shifts[date] = [];
				}
				shifts[date].push(game);

				return shifts;
			}, {});

			// Edit: to add it in the array format instead
			const groupArrays = Object.keys(shifts).map((date) => {
				return {
					date,
					shifts: shifts[date]
				};
			});
			setDailyShiftReportData(groupArrays);
		}
		setLoader(false);

	}

	return (
		<ScrollView contentContainerStyle={styles.scrollInner}>
			<ButtonHeader
				location={currentLocation?.field2 || currentLocation?.id}
				title={currentButton.field2}
			/>
			<Content flex>

				{shiftInfo?.message=="Get Shifts empty" &&
					<IconListControl
						color={EStyleSheet.value('$blue')}
						title={t('Start Shift Report')}
						icon="md-checkbox-outline"
						to={{
							pathname: `/location/${locationId}/dailyShiftReport/${buttonId}`,
							state: { reportDate: moment().format('YYYY-MM-DD'), shiftInfo: null },
						}}
					/>
				}
				{shiftInfo && (shiftInfo?.locationId == locationId || shiftInfo?.shiftType=="end") &&
					<IconListControl
						color={EStyleSheet.value('$blue')}
						title={t(isResume ? 'Resume Shift Report' : 'Start Shift Report')}
						icon="md-checkbox-outline"
						to={{
							pathname: `/location/${locationId}/dailyShiftReport/${buttonId}`,
							state: { reportDate: moment().format('YYYY-MM-DD'), shiftInfo: null },
						}}
					/>
				}
				{shiftInfo  && shiftInfo?.locationId != locationId && shiftInfo?.shiftType=="start" &&
					<Paragraph content={`You have already started your shift in location ${locations.find(({ id }) => id === shiftInfo?.locationId?.toString())?.field2 ? locations.find(({ id }) => id === shiftInfo?.locationId?.toString())?.field2 : "building Id: "+shiftInfo?.locationId}.`} />
				}
				{dailyShiftReportData?.map((item, index) => (
					<View>
						<View style={{ marginLeft: 10, marginTop: 20 }}>
							<SubTitle title={`${moment(item?.date, "YYYY-MM-DD").format("dddd, MMM DD, YYYY")}`} />
						</View>
						{item?.shifts?.map((shift, index) => (
							<View key={index} style={styles.shadow}>
								<TouchableOpacity
									style={styles.container}
									onPress={() => history.push({
										pathname: `/location/${locationId}/dailyShiftReport/${buttonId}`,
										state: { reportDate: moment(shift.createdAt).format('YYYY-MM-DD'), shiftInfo: shift },
									})}
								>
									<Text style={styles.title}>
										{shift.name}, ({shift.startTime} - {shift.endTime})
									</Text>
								</TouchableOpacity>
							</View>
						))}
					</View>
				))}
				{loader &&
					<ActivityIndicator size={"large"} />}
			</Content>
		</ScrollView>
	);
}

const mapStateToProps = createStructuredSelector({
	buttons: makeSelectButtons(),
	locations: makeSelectLocations(),
});

const withConnect = connect(
	mapStateToProps,
);

export default compose(
	withConnect,
	memo,
)(DailyShiftReportHistory);
