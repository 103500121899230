import { takeLatest, call, put } from "redux-saga/effects";

import request from "../../../utils/request";
import { formSubmittedSuccess, formSubmittedFail, changeInput, authSuccess } from "./actions";
import login from "../../../services/login";

import { SUBMIT_SSO, SUBMIT_AUTH, SUBMIT_FORM } from "./constants";
// import { changeInput } from "../OtpBox/actions";
import API from "../../../config/api";
import { setUser } from "../../../providers/UserProvider/actions";
import { subscribePushNotification } from "../../../providers/NotificationsProvider/actions";
import { applyMagic } from "../styles";
import { useDispatch } from "react-redux";
import { Axios } from "axios";

function* retreiveUser() {
  try {
    const url = `${API()}/action/getPerson`;

    const options = {
      url,
      method: "GET",
    };

    const { data } = yield call(request, options);

    if (typeof data === "string") {
      yield put(formSubmittedFail());
    } else {
      const token = yield call(login.getToken);
      const user = yield call(login.getUser);
      const newUser = { ...user, ...data };
      //console.log(user,"user",newUser,"newUser",data,"data",token);
      yield call(login.setUser, newUser);
      yield put(setUser(newUser));
      const termsAccepted = newUser.addInfo2 !== null ? newUser.addInfo2 : false;
      yield put(formSubmittedSuccess(true));
      // yield put(formSubmittedSuccess(false));
      yield put(subscribePushNotification(newUser, false));
    }

    // yield put(formSubmittedSuccess(false));
  } catch (err) {
    yield put(formSubmittedFail(err));
  }
}


function* submitAuth({ username }) {
  const url = `${API()}/action/loginV3`;
  const options = {
    url,
    method: "POST",
    params: {
      username: applyMagic(username),
      autoLogin: true,
      getToken: true,
      ajax: true,
      isOAuth2: true,
    },
  };

  try {
    // Make the request using the request function
    const { data } = yield call(request, options);
    console.log(options, "options", data, "data");

    if (typeof data === "string") {
      yield put(formSubmittedFail());
    } else {
      yield put(authSuccess(data));
    }
  } catch (err) {
    yield put(formSubmittedFail(err));
  }
}
async function postData(url, token) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Authorization": `Bearer ${token}`,
    }
  });
  if (response?.ok) {
    return response.json();
  }
  return null // parses JSON response into native JavaScript objects
}


function* submitSSO({ token, locationId }) {
  //console.log(token, locationId, "token, locationId");
  try {
    const url = `${API()}/action/sso-login/${locationId}?getToken=true&ajax=true`;
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    const options = {
      url,
      method: "POST",
      headers
    };
    const data = yield call(postData, url, token);
    if (data) {
      if (data?.tokens) {
        console.log(data.tokens, "SSO Data");
        yield call(login.init, data.tokens);
        yield call(retreiveUser);
      }
      else {
        yield put(formSubmittedFail(err));
      }
    }
  } catch (err) {
    yield put(formSubmittedFail(err));
  }
}

function* submitLogin({ username, password }) {
  const creds = {
    username,
    password,
  };
  //console.log(creds,"creds");
  yield call(login.setCreds, creds);

  const url = `${API()}/action/loginV2`;
  const options = {
    url,
    method: "POST",
    params: {
      username: applyMagic(username),
      password: applyMagic(password),
      // username: username,
      // password: password,
      autoLogin: true,
      getToken: true,
      ajax: true,
    },
  };

  //console.log(options, "options");

  try {
    const { data } = yield call(request, options);
    if (typeof data === "string") {
      yield put(formSubmittedFail());
    } else {
      //console.log(
      //   data,
      //   "data.verificationIddata.verificationIddata.verificationId"
      // );
      if (data.verificationId) {
        yield put(changeInput(data.verificationId, "verificationId"));
        yield put(formSubmittedSuccess(false));
      }
      else if (data.tokens) {
        yield call(login.init, data.tokens);
        yield call(retreiveUser);
      }
      else {
        yield put(formSubmittedFail());
      }
    }
  } catch (err) {
    yield put(formSubmittedFail(err));
  }
}

// Individual exports for testing
export default function* LoginBoxSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(SUBMIT_FORM, submitLogin);
  yield takeLatest(SUBMIT_AUTH, submitAuth);
  yield takeLatest(SUBMIT_SSO, submitSSO);
}
