import React, { useState, useEffect, createRef } from "react";
import { Text, TouchableWithoutFeedback, View, TextInput } from "react-native";
// import DatePicker from 'react-native-date-picker';

// import EStyleSheet from 'react-native-extended-stylesheet';
import { stylesInput } from "./styles";
// import DatePicker from 'react-native-date-picker';
import { createElement } from "react-native-web";
import moment from "moment";
import { unset } from "lodash";

const DatePickerCustom = React.forwardRef(
  (
    {
      placeholder,
      children,
      value,
      currentValue = new Date(),
      onChangeText,
      onChange,
      onSubmitEditing,
      autoCompleteType,
      autoCapitalize,
      returnKeyType,
      returnKeyLabel,
      textContentType,
      secureTextEntry,
      keyboardType,
      multiline,
      numberOfLines = 1,
      mode = "time",
      error,
      onBlur = () => { },
      maximumDate = false
    },
    ref
  ) => {
    // const currentValue = new Date();
    const [active, setActive] = useState(currentValue);
    const [open, setOpen] = useState(false);
    // const inputRef = ref || createRef();
    // useEffect(() => {
    //   //console.log(currentValue, 'currentValue');
    //   setActive(currentValue.length > 0 || inputRef.current.isFocused());
    // }, [currentValue]);

    const DismissKeyboard = ({ children }) => (
      <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
        {children}
      </TouchableWithoutFeedback>
    );
    return (
      <View>
        <View>
          <Text
            style={[stylesInput.placeholder]}
          //onPress={() => inputRef.current.focus()}
          //onFocus={() => inputRef.current.focus()}
          >
            {placeholder}
          </Text>
          {/* <DismissKeyboard>
            <TextInput
              style={[stylesInput.input, error && stylesInput.inputError]}
              value={moment(currentValue).format('YYYY-MM-DD hh:mm a')}
              // onChangeText={onChangeText}
              // onChange={onChange}
              onFocus={() => {
                setActive(true);
                setOpen(true);
              }}
              onTouchStart={() => {
                setActive(true);
                setOpen(true);
              }}
              onBlur={() => {
                setActive(currentValue.length > 0);
                onBlur();
              }}
              keyboardAppearance={'light'}
              // ref={inputRef}
              placeholder={placeholder}
            />
          </DismissKeyboard> */}
          {mode == "time" ?
            <>
              {createElement("input", {
                type: mode,
                value: currentValue ? moment(currentValue, "hh:mm a").format("HH:mm") : null,
                onChange: (event) => {
                  //console.log(event.target.value, "value");
                  onChange(moment(event.target.value, "HH:mm").format("hh:mm a"));
                },
                style: {
                  border: 0,
                },
              })}
            </>
            :
            <>
              {createElement("input", {
                type: mode,
                value: currentValue,
                max: maximumDate && moment().format("YYYY-MM-DD"),
                onChange: (event) => {
                  //console.log(event.target.value, "value");
                  onChange(event.target.value);
                },
                style: {
                  border: 0,
                },
              })}
            </>
          }
          {/* <DatePicker
            modal
            open={open}
            date={currentValue}
            onConfirm={date => {
              setOpen(false);
              onChange(date);
            }}
            onCancel={() => {
              setOpen(false);
            }}
          /> */}
        </View>
      </View>
    );
  }
);

export default DatePickerCustom;
