/*
 *
 * LoginBox reducer
 *
 */
import produce from "immer";
import {
  SUBMIT_FORM,
  CHANGE_INPUT,
  ERROR_LOGIN,
  FORM_SUBMITTED_FAIL,
  FORM_SUBMITTED_SUCCESS,
  UNMOUNT,
  TRYLOCALAUTH,
  CANLOCALAUTH,
  AUTH_SUCCESS,
  SUBMIT_AUTH,
  SUBMIT_SSO,
  SSO_COMPLETED,
  RESET_STATE,
} from "./constants";
import { LOCAL_AUTH_TRY_MAX } from "../../../config/master";
import { Platform } from "react-native";

// The initial state of the App
export const initialState = {
  loading: false,
  sso_Completed: false,
  username: "",
  password: "",
  remember: false,
  terms: false,
  sent: false,
  verificationId: 0,
  tryAuth: 0,
  localAuthSupport: false,
  error: {
    username: false,
    password: false,
    match: false,
  },
  loginType: "",
  loginDetails: {
    client_id: "",
    auth_url: "",
    token_url: "",
    scopes: ["openid", "email"],
    locationId: 0,
    extraParams: {},
  },
};

/* eslint-disable default-case, no-param-reassign */
const loginBoxReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SUBMIT_AUTH:
        draft.loading = true;
        draft.error.username = false;
        draft.error.password = false;
        draft.error.match = false;
        break;
      case SUBMIT_SSO:
        draft.loading = true;
        break;
      case SUBMIT_FORM:
        draft.loading = true;
        draft.error.username = false;
        draft.error.password = false;
        draft.error.match = false;
        break;
      case CHANGE_INPUT:
        draft[action.name] = action.value;
        draft.error.username = false;
        draft.error.password = false;
        draft.error.match = false;
        break;
      case AUTH_SUCCESS:
        if (action.data.loginType == "oauth2") {
          const loginDetails = JSON.parse(action.data.loginDetails);
          //console.log(loginDetails, "loginDetails", loginDetails.locationId);
          let authorizationUrl = loginDetails.auth_url;
          let ParamsUrl = {};
          if (loginDetails.auth_url_params && loginDetails.auth_url_params != '') {
            let authParams = loginDetails.auth_url_params.split("&");
            for (let i = 0; i < authParams.length; i++) {
              const rondomUuid='xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0,
                    v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
              });
              //console.log(rondomUuid,"rondomUuid");
              const uuid = Platform.OS=="web"? crypto.randomUUID():rondomUuid;
              const param = authParams[i].toString();
              let x = (param != null && param != '' ? (param.includes(":uuid") ? param.replace(":uuid", uuid) :
                (param.includes(":email") ? param.replace(":email", draft.username) : param)) : "");
              x = x.split('=')
              if (x.length > 1) {
                ParamsUrl[x[0]] = x[1];
              }
            }
            //console.log(ParamsUrl, "ParamsUrl");
            draft.loginDetails.extraParams = ParamsUrl;
          }
          //console.log(authorizationUrl, "authorizationUrl", ParamsUrl);
          draft.loginDetails.client_id = loginDetails.client_id_pkce;
          draft.loginDetails.locationId = loginDetails.locationId;
          draft.loginDetails.auth_url = authorizationUrl;
          draft.loginDetails.token_url = loginDetails.token_url;
          draft.loginDetails.scopes = loginDetails.scopes.split(" ");
        }
        draft.loginType = action.data.loginType;
        draft.loading = false;
        break;
      case SSO_COMPLETED:
        draft.sso_Completed = true;
        break;
      case FORM_SUBMITTED_SUCCESS:
        draft.sent = true;
        draft.terms = action.terms;
        draft.loading = false;
        break;
      case FORM_SUBMITTED_FAIL:
        draft.loading = false;
        draft.error.match = true;
        draft.tryAuth = LOCAL_AUTH_TRY_MAX + 1;
        break;
      case TRYLOCALAUTH:
        draft.tryAuth += 1;
        break;
      case ERROR_LOGIN:
        draft.loading = false;
        Object.keys(action.error).forEach((errorType) => {
          draft.error[errorType] = action.error[errorType];
        });
        draft.tryAuth = LOCAL_AUTH_TRY_MAX + 1;
        break;
      case UNMOUNT:
        draft.error.match = false;
        draft.tryAuth = 0;
        break;
      case CANLOCALAUTH:
        draft.localAuthSupport = action.support;
        break;
    }
  });

export default loginBoxReducer;
