import React, { useEffect, useState } from 'react';
import {
	View,
	Picker,
	Text,
	TouchableOpacity,
	ScrollView,
	Platform,
} from 'react-native';
import { stylesPicker as styles } from './styles';
import Modal from '../../services/modal';
import { Ionicons, Feather } from '@expo/vector-icons';
import EStyleSheet from 'react-native-extended-stylesheet';
import Control from '../Control';
import { t } from '../../services/i18n';
import Paragraph from '../Content/Paragraph';

const MultiPickerCustom = ({
	options = [], selected = [], onChange,
}) => {
	const [isShow, setIsShow] = useState(false);
	const [selectedItems, setSelectedItems] = useState(selected || []);

	useEffect(() => {
		setSelectedItems(selected);
	}, [selected])
	const toggleOption = (option) => {
		if (selectedItems.includes(option)) {
			setSelectedItems(selectedItems.filter((item) => item !== option));
		} else {
			setSelectedItems([...selectedItems, option]);
		}
	};
	return (
		<>
			<TouchableOpacity style={{ width: "100%", minHeight: 30 }} onPress={() => { setIsShow(true); }}>
				<View style={{ flexWrap: 'wrap', flexDirection: "row" }}>
					{selectedItems.map((item) => {
						return (
							<View style={{ borderRadius: 10, margin: 5, padding: 5, paddingHorizontal: 10, backgroundColor: "#dee0e3", alignItems: "center" }}>
								<Text>{item}</Text>
							</View>
						)
					})}
				</View>
			</TouchableOpacity>
			<ScrollView>
				<Modal
					backdropColor={EStyleSheet.value('$white')}
					backdropOpacity={0.70}
					onBackdropPress={setIsShow}
					onBackButtonPress={setIsShow}
					animationIn="zoomIn"
					animationOut="zoomOut"
					useNativeDriver
					scrollHorizontal={true}
					isVisible={isShow}>
					<View style={[{ justifyContent: "center", backgroundColor: "#fff", marginVertical: 40, padding:5, borderWidth:1, borderRadius:10 }, Platform.OS == "web" && { width: 350, height:"100%",alignSelf:"center" }]}>
						<TouchableOpacity onPress={() => { setIsShow(false) }} style={[styles.roundControl, { alignSelf: "flex-end", margin: 10 }]}>
							<View style={[styles.bubbleClose]}>
								<Ionicons style={styles.iconClose} name="md-close" color={"#fff"} />
							</View>
						</TouchableOpacity>
						<ScrollView showsVerticalScrollIndicator={false}>
							<View style={[{  backgroundColor: "#fff", alignItems: "center", }]}>
								{options.map((option) => (
									<TouchableOpacity
										key={option}
										onPress={() => toggleOption(option)}
										style={{
											borderRadius: 10, marginVertical: 1, padding: 5, paddingHorizontal: 10, width: "100%", flexDirection: "row", justifyContent: "space-between"
										}}
									>
										<Paragraph style={{ fontSize: 20 }}>{option}</Paragraph>
										<View style={{ borderRadius: 5, backgroundColor: selectedItems.includes(option) ? EStyleSheet.value('$blue') : 'white', height: 25, width: 25, borderColor: "grey", borderWidth: 1, alignSelf: "center", alignItems: "center", justifyContent: "center" }}>
											{selectedItems.includes(option) &&
												<Ionicons style={{ color: EStyleSheet.value('$white'), fontSize: 20, fontWeight: "bold", alignSelf: "center" }} name="checkmark" />
											}
										</View>
									</TouchableOpacity>
								))}
							</View>

						</ScrollView>
							<Control
								onPress={() => {
									onChange(selectedItems); setIsShow(false)
								}}
								title={t("Apply")}
							/>
					</View>
				</Modal >
			</ScrollView >
		</>
	)
};

export default MultiPickerCustom;
