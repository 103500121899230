import { takeLatest, call, put } from 'redux-saga/effects';
import API from '../../config/api';
import { LOAD_BUTTONS } from './constants';
import { buttonsLoadedSuccess, buttonsLoadedFail } from './actions';
import login from '../../services/login';
import { loadNotifications } from '../NotificationsProvider/actions';
import ENUM_TYPE_BUTTON from '../../constants/enumTypeButton';
// import RNFetchBlob from 'react-native-blob-util';
import request from '../../utils/request';
// We had to create a custom fetch instead of axios request
// Because of a weird behaviour that wasn't returning every buttons for this request
// even when the request was good and fully returning every buttons on a browser or postman...
// With axios it was caching or smth that we do not understand
async function callLoadButtons(locationId) {
    const user = await login.getUser();
    const url = `${API()}/action/fetchGenData?ajax=true&field5=true&field15=${locationId}&personId=${user.personId}`;
    const options = {
        url,
        method: 'POST',
    };
    try {
        const {data} = await request(options);
        if (data) {
            return data;
        }
        console.warn(res);
        return 'error';
    } catch (error) {
        console.warn(error);
        return 'error';
    }
}

async function saveFile(data) {
    try {
        const buttonList = [];
        const token = await login.getToken();
        for (const button of data) {
            // await data.map(async (button) => {
            let btn = button;
            if (button.field6 == ENUM_TYPE_BUTTON.DOCUMENT) {

                // try {
                //     const secureDocumentId = Number(button.field7);

                //     const source = {
                //         uri: `${API()}/action/getSecureFiles?id=${secureDocumentId}`,
                //         cache: true,
                //     };
                //     //stop download if the file is already downloaded
                //     //console.log(RNFetchBlob.fs.dirs.DocumentDir + `/${secureDocumentId}.pdf`,"pathExits");
                //     const exist = await RNFetchBlob.fs.exists(RNFetchBlob.fs.dirs.DocumentDir + `/${secureDocumentId}.pdf`);
                //     if (exist) {
                //         btn = { ...btn, fileCacheUrl: RNFetchBlob.fs.dirs.DocumentDir + `/${secureDocumentId}.pdf` };
                //         buttonList.push(btn);
                //         continue;
                //     }
                //     else {
                //         const config = {
                //             fileCache: true,
                //             appendExt: 'pdf',
                //             session:"pdf",
                //             path: `${RNFetchBlob.fs.dirs.DocumentDir}/${secureDocumentId}.pdf`,
                //             // addAndroidDownloads: {
                //             //     useDownloadManager: true,
                //             //     notification: false,
                //             //     path: `${RNFetchBlob.fs.dirs.DownloadDir}/${secureDocumentId}.pdf`,
                //             //     description: 'Downloading file.'
                //             // }
                //         };
                //         const res = await RNFetchBlob.config(config).fetch('GET', source.uri, {
                //             Authorization: `Bearer ${token}`
                //         });
                //         if (res.path()) {
                //             btn = { ...btn, fileCacheUrl: res.path() };
                //             //console.log(btn,"btn",res.path());
                //             buttonList.push(btn);
                //         }
                //         else {
                //             //console.log("btn added without change");
                //             buttonList.push(btn);
                //         }
                //     }
                // } catch (error) {
                //     buttonList.push(btn);
                //     console.warn(error);
                //     return data;
                // }
            }
            else {
                buttonList.push(btn);
            }
        };
        //console.log(buttonList.length, "buttonList", data.length);
        return buttonList;
    } catch (error) {
        console.warn(error);
        return data;
    }

}
//addedByYounis
async function callLoadButtonsByExt(locationId, ids, field17, isNotInOrIn1, isNotInOrInValue1, isNotInOrIn2, isNotInOrInValue2) {
    var idsStr = ids == null ? '' : `&ids=${ids}`;
    var field17Str = field17 == null ? '' : `&field17=${field17}`;
    var isNotInOrInStr1 = isNotInOrIn1 == null ? '' : `&${isNotInOrIn1.split(":")[0]}=${isNotInOrIn1.split(":")[1]}`;
    var isNotInOrInValueStr1 = isNotInOrInValue1 == null ? '' : `&${isNotInOrIn1.split(":")[0]}Value=${isNotInOrInValue1}`;
    var isNotInOrInStr2 = isNotInOrIn2 == null ? '' : `&${isNotInOrIn2.split(":")[0]}=${isNotInOrIn2.split(":")[1]}`;
    var isNotInOrInValueStr2 = isNotInOrInValue2 == null ? '' : `&${isNotInOrIn2.split(":")[0]}Value=${isNotInOrInValue2}`;
    //console.log(idsStr,"idsStr");
    const user = await login.getUser();
    const url = `${API()}/action/fetchGenData?ajax=true&field5=true&personId=${user.personId}&field15=${locationId}` + idsStr + field17Str + isNotInOrInStr1 + isNotInOrInValueStr1 + isNotInOrInStr2 + isNotInOrInValueStr2;
    const options = {
        url,
        method: 'POST',
    };
    try {
        const {data} = await request(options);
        if (data) {
            return data;
        }
        console.warn(res);
        return 'error';
    } catch (error) {
        console.warn(error);
        return 'error';
    }
}
//Updated by Younis start
// addInfo1: role
// 0 = admin
// 1 = manager
// 2 = emergency team
// 3 = user
// 4 = resident
// 5 = guest
// addInfo7: JSON of accessible pages json { "[location-id]" : { "ids": "[comma-separated list of button ids]", "all": true or false, indicates that all pages are accessible. } }
function* loadButtons({ location }) {
    const { addInfo1: role, addInfo7 } = yield call(login.getUser);
    const userAccess = JSON.parse(addInfo7);
    const currentLocationUserAccess = userAccess?.[location.id]?.ids ?? [];
    const currentLocationUserFullAccess = userAccess?.[location.id]?.all ?? false;
    try {
        const emergencyLocation = JSON.parse(location.field18);
        const fullAccessLocation = emergencyLocation?.fullAccess === 'true' || emergencyLocation?.fullAccess === true;
        const isEmergencyUser = role === '2';
        const isAdmin = role === '0';
        const locationAccess = userAccess?.[location.id];
        var data = null;
        console.warn(currentLocationUserAccess);
        if (fullAccessLocation || isAdmin) {
            data = yield call(callLoadButtons, location.id);
        } else if (isEmergencyUser) {
            data = yield call(callLoadButtonsByExt, location.id, null, true, null, null, null, null);
        } else if (locationAccess?.all == true || locationAccess?.all == 'true') {
            data = yield call(callLoadButtons, location.id);
        } else {
            if (locationAccess?.allSecurity == true || locationAccess?.allSecurity == 'true') {
                data = yield call(callLoadButtonsByExt, location.id, null, null, "in1:field31", "true", "notIn2:id", currentLocationUserAccess.length > 0 ? currentLocationUserAccess : "null");
            } else if (locationAccess?.allProperty == true || locationAccess?.allProperty == 'true') {
                data = yield call(callLoadButtonsByExt, location.id, null, null, "notIn1:field31", "true", "notIn2:id", currentLocationUserAccess.length > 0 ? currentLocationUserAccess : "null");
            }
            if (currentLocationUserAccess.length > 0) {
                var data2 = yield call(callLoadButtonsByExt, location.id, currentLocationUserAccess, null, null, null);
                data = data != null ? data.concat(data2) : data2;
            }
        }
        if (typeof data === 'object') {
            yield put(buttonsLoadedSuccess(data, location.id));
            // const buttonList = yield call(saveFile, data);
            // yield put(buttonsLoadedSuccess(buttonList, location.id));
            const user = yield call(login.getUser);
            yield put(loadNotifications(user));
        } else {
            console.warn(data);
            yield put(buttonsLoadedFail(data));
        }
    } catch (err) {
        console.warn(err);
        yield put(buttonsLoadedFail("Error loading buttons. Please try again."));
    }
}
//Updated by Younis end
export default function* ButtonsProviderSaga() {
    yield takeLatest(LOAD_BUTTONS, loadButtons);
}