import { Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
	shadow: {
		borderRadius: '$borderRadius',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 3,
		marginVertical: 3
	},
	container: {
		borderRadius: '$borderRadius',
		backgroundColor: '$white',
		paddingHorizontal: '.5rem',
		paddingVertical: '.5rem',
		borderLeftWidth: 8,
		borderLeftColor: '$green',
	},
	info: {
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
	},
	delIcon: {
		fontSize: '1.5rem',
		color: 'red',
		textAlign:"center",
		marginTop:'.8rem'
	},
	iconLeft: {
		fontSize: '1rem',
		color: '$grey',
		marginRight: '1rem',
	},
	title: {
		// fontSize: '.8rem',
		color: '$text',
		fontFamily: '$fontMedium',
		textAlign: 'left',
		flex: 1,
		marginBottom: 3,
		paddingHorizontal: '.3rem',
		paddingVertical: '.3rem',
	},
	date: {
		fontSize: '.6rem',
		color: '$text',
		fontFamily: '$fontBold',
		textAlign: 'left',
	},
	name: {
		fontSize: '.6rem',
		color: '$grey',
		fontFamily: '$fontMedium',
		textAlign: 'left',
		width:'10rem'
	},
	desc: {
		fontSize: '.6rem',
		color: '$grey',
		fontFamily: '$fontMedium',
		textAlign: 'left',
	},
	iconRight: {
		fontSize: '2rem',
		color: '$grey',
	},
	text: {
		color: '$text',
		fontFamily: '$fontRegular',
		paddingLeft: '1rem',
		marginBottom: '.2rem',
	},
	link: {
		color: '$blue',
		fontFamily: '$fontBold',
	},
	scrollInner: {
		flexGrow: 1,
		marginBottom: '2rem',
	},
	image: {
		height: 100,
		width: 100,
		aspectRatio: 0.9,
		borderRadius: Platform.OS === 'ios' ? '$borderRadius' : 0,
		padding: 10
	},
	history_image: {
		height: 50,
		width: 50,
		aspectRatio: 0.9,
		borderRadius: Platform.OS === 'ios' ? '$borderRadius' : 0,
		padding: 10
	},
	removeImageIcon: {
		fontSize: '.9rem',
		fontWeight: '600',
		color: '$text',
		position: 'absolute',
		right: -5,
		top: -5,
		padding: 1,
		borderRadius: 50,
		backgroundColor: '$white',
		zIndex: 1000,
		shadowColor: '$text',
		shadowOffset: {
			width: 0,
			height: 12,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
});

export default styles;
