import React, { useEffect, useState } from 'react';
import { Image, ActivityIndicator, TouchableOpacity, View } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import axios from 'axios';
import Modal from '../../services/modal';
import { styles } from './style';
import { Ionicons, Feather } from '@expo/vector-icons';
import SubTitle from '../../components/Content/SubTitle';
import Paragraph from '../../components/Content/Paragraph';

function ImageSecured({
	progressiveRenderingEnabled, style, resizeMode, source, isMax = false, title = null
}) {
	const [isVisible, setIsVisible] = useState(false);
	const [path, setPath] = useState(null);
	const [loading, setLoading] = useState(true);
	async function getImageSecured() {
		try {
			const res = await axios.get(source.uri, {
				responseType: 'blob',
			});
			const reader = new window.FileReader();
			reader.readAsDataURL(res.data);
			reader.onload = () => {
				setPath(reader.result);
			};
			setLoading(false);
		} catch (error) {
			console.warn(error);
		}
	}
	useEffect(() => {
		getImageSecured();
	}, [source]);

	if (loading) {
		return <ActivityIndicator size="small" color={EStyleSheet.value('$blue')} style={{ padding: EStyleSheet.value('2rem') }} />;
	}

	return (
		<View>
			<TouchableOpacity onPress={() => { setIsVisible(true) }}>
				<Image
					progressiveRenderingEnabled={progressiveRenderingEnabled}
					style={style}
					resizeMode={resizeMode}
					source={{ uri: path }}
				/>
			</TouchableOpacity>
			<Modal
				backdropColor={EStyleSheet.value('$white')}
				backdropOpacity={0.75}
				isVisible={isVisible && isMax}
				onBackdropPress={() => { setIsVisible(false) }}
				onBackButtonPress={() => { setIsVisible(false) }}
				animationIn="zoomIn"
				animationOut="zoomOut"
				onSwipeComplete={() => { setIsVisible(false) }}
				useNativeDriver
				swipeDirection={['down', 'left', 'up', 'right']}
				onModalHide={() => (isVisible ? relaunch() : null)}
			>
			<View style={[styles.modalView, { margin: "30%" }]}>
					<TouchableOpacity onPress={() => { setIsVisible(false) }} style={[styles.roundControl, { alignSelf: "flex-end", margin: 10 }]}>
						<View style={[styles.bubbleClose]}>
							<Ionicons style={styles.iconClose} name="md-close" />
						</View>
					</TouchableOpacity>
					<View style={[styles.imageContainer, { alignItems: "center", paddingTop: 10 }]}>
						{title &&
							<Paragraph content={title}></Paragraph>
						}
						{path && <Image resizeMode="contain" style={styles.image} source={{ uri: path }} />}
					</View>
				</View>
			</Modal>
		</View >
	);
}

export default ImageSecured;
