import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import {
	Alert,
	Text, TextInput, TouchableOpacity, View, ScrollView, ActivityIndicator
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useHistory, useLocation, useParams } from '../../../services/router';
import ButtonHeader from '../../../components/ButtonHeader';
import Content from '../../../components/Content/Content';
import Button from '../../../components/Button';
import styles from './styles';
import { makeSelectButtons } from '../../../providers/ButtonsProvider/selectors';
import { makeSelectLocations } from '../../../providers/LocationsProvider/selectors';
import IconListControl from '../../../components/IconListControl';
import EStyleSheet from 'react-native-extended-stylesheet';
import { t } from '../../../services/i18n';
import API from '../../../config/api';
import request from '../../../utils/request';
import SubTitle from '../../../components/Content/SubTitle';

function IncidentReportHistory({ buttons, locations }) {
	const history = useHistory();
	const location = useLocation();
	const [dailyShiftReportData, setDailyShiftReportData] = useState([]);
	const [date, setDate] = useState(moment().format('YYYY-MM-DD hh:mm a'));
	const [note, setNote] = useState('');
	const [name, setName] = useState('');
	const [shitStarted, setShitStarted] = useState(false);
	const [modal, setModal] = useState(false);
	const [image, setImage] = useState(false);
	const [loader, setLoader] = useState(false);
	const { locationId, buttonId } = useParams();
	const currentLocation = locations.find(({ id }) => id === locationId);
	const currentButton = buttons.find((button) => button.id === buttonId);

	// useEffect(async () => {
	// 	const MyDailyShiftReportData = await AsyncStorage.getItem('dailyShiftReportData');
	// 	if (MyDailyShiftReportData) {
	// 		//console.log(JSON.parse(MyDailyShiftReportData));
	// 		setDailyShiftReportData(JSON.parse(MyDailyShiftReportData));
	// 	}
	// }, []);
	useEffect(() => {
		const fetchIncidentReportData = async () => {
		  setLoader(true);
		  try {
			const url = `${API()}/action/incidentReport?locationId=${locationId}&orderBy=updatedAt&asc=false`;
	  
			const options = {
			  url,
			  method: 'GET',
			};
	  
			const { data } = await request(options);
			if (data && data.length) {
			  const shifts = data.reduce((shifts, game) => {
				const date = moment(game.updatedAt, "YYYY-MM-DDThh:mm:ss.000").format("YYYY-MM-DD");
				if (!shifts[date]) {
				  shifts[date] = [];
				}
				shifts[date].push(game);
				return shifts;
			  }, {});
	  
			  // Convert to array format
			  const groupArrays = Object.keys(shifts).map((date) => ({
				date,
				shifts: shifts[date],
			  }));
			  setDailyShiftReportData(groupArrays);
			}
		  } catch (error) {
			console.error('Error fetching incident report data:', error);
		  } finally {
			setLoader(false);
		  }
		};
	  
		fetchIncidentReportData();
	  }, []);  // Added locationId to dependencies
	  
	return (
		<ScrollView contentContainerStyle={styles.scrollInner}>
			<ButtonHeader
				location={currentLocation?.field2 || currentLocation?.id}
				title={currentButton.field2}
			/>
			<Content flex>

				<IconListControl
					color={EStyleSheet.value('$blue')}
					title={t('Enter Incident Report')}
					icon="md-checkbox-outline"
					to={{
						pathname: `/location/${locationId}/incidentReport/${buttonId}`,
						state: { reportDate: moment().format('YYYY-MM-DD'), incidentInfo: null },
					}}
				/>
				{dailyShiftReportData?.map((item, index) => (
					<View>
						<View style={{ marginLeft: 10, marginTop: 20 }}>
							<SubTitle title={`${moment(item?.date, "YYYY-MM-DD").format("dddd, MMM DD, YYYY")}`} />
						</View>
						{item?.shifts?.map((incident, index) => (
							<View key={index} style={styles.shadow}>
								<TouchableOpacity
									style={styles.container}
									onPress={() => history.push({
										pathname: `/location/${locationId}/incidentReport/${buttonId}`,
										state: { reportDate: moment(incident?.createdAt).format('YYYY-MM-DD'), incidentInfo: incident },
									})}
								>
									<Text style={styles.title}>
										{`${incident?.firstName} ${incident?.lastName}`}
									</Text>
									<Text style={styles.description}>
										{incident?.incidentDesc}
									</Text>
								</TouchableOpacity>
							</View>
						))}
					</View>
				))}
				{loader &&
					<ActivityIndicator size={"large"} />}
			</Content>
		</ScrollView>
	);
}

const mapStateToProps = createStructuredSelector({
	buttons: makeSelectButtons(),
	locations: makeSelectLocations(),
});

const withConnect = connect(
	mapStateToProps,
);

export default compose(
	withConnect,
	memo,
)(IncidentReportHistory);
