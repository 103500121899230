import React, { memo, useEffect, createRef, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import moment from "moment";
import {
  Alert,
  KeyboardAvoidingView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Platform,
  ActivityIndicator, ScrollView
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useParams, useLocation, useHistory } from "../../../services/router";
import ButtonHeader from "../../../components/ButtonHeader";
import Content from "../../../components/Content/Content";
import Button from "../../../components/Button";
import styles from "./styles";
import { makeSelectButtons } from "../../../providers/ButtonsProvider/selectors";
import { makeSelectLocations } from "../../../providers/LocationsProvider/selectors";
import SubTitle from "../../../components/Content/SubTitle";
import ModalTakePicture from "../../../components/ChecklistCard/ModalTakePicture";
import Separator from "../../../components/Separator";
import Control from "../../../components/Control";
import { t } from "../../../services/i18n";
import Paragraph from "../../../components/Content/Paragraph";
import API from "../../../config/api";
import request from "../../../utils/request";
import login from "../../../services/login";
import ModalRead from "../../../components/ChecklistCard/ModalRead";
import {
  getCurrentLocation,
  stopLocation,
} from "../../../providers/UserLocationProvider/actions";
import { makeSelectUserLocation } from "../../../providers/UserLocationProvider/selectors";
import DatePicker from "../../../components/Form/DatePicker";
function DailyShiftReport({ buttons, locations, userLocations }) {
  const dispatch = useDispatch();
  const { locationId, buttonId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const startTimeRef = createRef();
  const endTimeRef = createRef();
  const [dailyShiftReportData, setDailyShiftReportData] = useState([]);
  const [date, setDate] = useState(
    moment(location.state?.reportDate, "YYYY-MM-DD").format("YYYY-MM-DD")
  );
  const [shiftInfo, setShiftInfo] = useState(location?.state?.shiftInfo);
  const [startTime, setStartTime] = useState(moment().format("hh:mm a"));
  const [endTime, setEndTime] = useState(moment().format("hh:mm a"));
  const [note, setNote] = useState("");
  const [name, setName] = useState("");
  const [shiftStarted, setShiftStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [imageViewModal, setImageViewModal] = useState(null);
  const [image, setImage] = useState(null);
  const currentLocation = locations.find(({ id }) => id === locationId);
  const currentButton = buttons.find((button) => button.id === buttonId);

  // useEffect(async () => {
  //   //console.log(userLocations, "userLocations");
  //   //console.log(user?.allowGeofencing, "user?.allowGeofencing");
  //   const user = await login.getUser();
  //   if (user?.allowGeofencing !== null && user?.allowGeofencing) {
  //     // getCurrentLocation(dispatch);
  //   }
  // }, [userLocations]);

  const getShiftInfo = async () => {
    try {
      //console.log("Inn");
      const url = `${API()}/action/shift?request=shift-info&responseType=json`;

      const options = {
        url,
        method: "GET",
      };

      const { data } = await request(options);
      //console.log(data, "datagetShiftInfo");

      if (data) {
        if (data.shiftType == "start") {
          setName(data?.name);
          // if(Platform.OS=="web")
          // {
          setEndTime(moment(data?.endTime, "HH:mm").format("hh:mm a"));
          setStartTime(moment(data?.startTime, "HH:mm").format("hh:mm a"));
          // }
          setDate(moment(data?.createdAt, "YYYY-MM-DD")?.format("YYYY-MM-DD"));
          setShiftInfo(data);
          setShiftStarted(true);
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  }
  useEffect(() => {
    const fetchShiftInfo = async () => {
      try {
        if (!location.state?.shiftInfo) {
          await getShiftInfo();
        } else {
          setName(location.state?.shiftInfo?.name);
          setEndTime(location.state?.shiftInfo?.endTime);
          setStartTime(location.state?.shiftInfo?.startTime);
          setDate(moment(location.state?.shiftInfo?.createdAt, "YYYY-MM-DD").format("YYYY-MM-DD"));
          setShiftStarted(true);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchShiftInfo();
  }, []);

  const getDailyShiftReportData = async (id) => {
    try {

      if (id) {
        setIsLoading(true);
        const url = `${API()}/action/shift?request=shift-log&shiftType=start&responseType=json&shiftId=${id}`;

        const options = {
          url,
          method: "GET",
        };

        const { data } = await request(options);

        setIsLoading(false);
        if (data && data?.length) {
          // setDailyShiftReportData(JSON.parse(MyDailyShiftReportData));
          setDailyShiftReportData(data);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  }
  useEffect(() => {
  const fetchDailyShiftReportData = async () => {
    try {
      if (shiftInfo?.id) {
        await getDailyShiftReportData(shiftInfo.id);
      }
    } catch (error) {
      console.error('Error fetching daily shift report data:', error);
    }
  };

  fetchDailyShiftReportData();
}, [shiftInfo?.id]);

  useEffect(() => {
    AsyncStorage.setItem(
      "dailyShiftReportData",
      JSON.stringify(dailyShiftReportData)
    );
  }, [dailyShiftReportData]);
  // useEffect(() => {
  // 	//console.log(startTimeRef.current.isFocused(), "startTimeRef");
  // 	//console.log(endTimeRef.current.isFocused(), "endTimeRef");
  // }, [startTimeRef,endTimeRef]);

  const askForLocationPermission = async () => {
    try {
      getCurrentLocation(dispatch);
    } catch (err) {
      console.warn(err);
    }
  };

  const OnPressShift = async () => {
    try {
      setBtnLoader(true);
      if (shiftStarted) {
        //console.log("aaa", { startTime, endTime });
        AsyncStorage.setItem("shifter", "");
        shiftStarted == true && setName("");
        shiftStarted == true && setStartTime("");
        shiftStarted == true && setEndTime("");

        const url = `${API()}/action/shift?request=run-shift&responseType=json`;
        const options = {
          url,
          method: "POST",
          headers: { "Content-Type": "application/json" },
          params: {
            ajax: true,
          },
          data: {
            locationId,
            shiftType: "end",
            name,
            startTime,
            endTime,
            dateTime: moment(),
          },
        };

        const { data } = await request(options);
        //console.log(data, "sdasdas");
        if (data && data?.id) {
          // alert(data.message);
          setBtnLoader(false);
          setShiftStarted(!shiftStarted);
          setDailyShiftReportData([]);
          stopLocation();
          history.goBack();
        }
        else {
          alert(data.message);
        }
      } else if (name === "") {
        setBtnLoader(false);
        alert("Please enter your name.");
      } else if (startTime === "") {
        setBtnLoader(false);
        alert("Please enter your shift start time.");
      } else if (endTime === "") {
        setBtnLoader(false);
        alert("Please enter your shift end time.");
      } else {
        AsyncStorage.setItem("shifter", JSON.stringify(name));
        const url = `${API()}/action/shift?request=run-shift&responseType=json`;

        const options = {
          url,
          method: "POST",
          headers: { "Content-Type": "application/json" },
          params: {
            ajax: true,
          },
          data: {
            locationId,
            shiftType: "start",
            name,
            startTime,
            endTime,
            dateTime: date,
          },
        };

        const { data } = await request(options);
        if (data && data?.id) {
          setBtnLoader(false);
          // alert(data.message);
          setShiftInfo({ id: data?.id })
          // await getShiftInfo();
          setShiftStarted(!shiftStarted);
          getDailyShiftReportData(data?.id);
          const user = await login.getUser();
          if (user?.allowGeofencing !== null && user?.allowGeofencing) {
            await askForLocationPermission();
          }
        }
        else {
          alert(data.message);
        }
        //console.log(data, "data");
      }
    }
    catch (err) {
      //console.log(err);
    }
  };

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) {
      byteString = atob(dataURI.split(",")[1]);
    } else {
      byteString = unescape(dataURI.split(",")[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  const onAddLog = async () => {
    setIsLoading(true);
    try {
      let mediaId = null;
      if (image) {
        // try to upload Image
        const pic = image;
        const form = new FormData();

        if (Platform.OS === "web") {
          const fileType = pic.uri.split(";")[0].split("/")[1];
          const file = dataURItoBlob(pic.uri);
          // append the pics in the form data
          form.append(
            "file",
            file,
            `${shiftInfo?.id}-${dailyShiftReportData?.length}.${fileType}`
          );
        } else {
          const uriParts = pic.uri.split(".");
          const fileType = uriParts[uriParts.length - 1];

          //console.log(pic.uri, uriParts, fileType, "fileType");
          // append the pics in the form data -${moment().format("YYYYMMDDhhmmss")}
          form.append("file", {
            uri: pic.uri,
            name: `${shiftInfo.id}namess.${fileType}`,
            type: `image/${fileType}`,
          });
        }
        form.append("ajax", true);
        const url = `${API()}/action/uploadSecureFile`;

        const options = {
          url,
          method: "POST",
          data: form,
          headers: {
            "Content-Type": "multipart/form-data",
            // 'Authorization': token
          },
        };

        let fetchResponse = null;
        let imgData = null;
        if (Platform.OS === "web") {
          fetchResponse = await request(options);
          imgData = fetchResponse?.data?.media?.mediaIds;
        } else {
          const uploadImage = await fetch(url, {
            method: "POST",
            body: form,
            headers: options.headers,
          });
          //console.log(uploadImage, "uploadImage");
          fetchResponse = await uploadImage.json();
          //console.log(fetchResponse, "fetchResponse");
          imgData = fetchResponse?.media?.mediaIds;
        }

        //console.log(fetchResponse, imgData, "imgData");

        const split = imgData?.length && imgData[0].split(" - ");
        //console.log(split, "split");
        mediaId = split?.length ? split[0] : null;
        //console.log(mediaId, "mediaId");
      }
      const url = `${API()}/action/shift?request=shift-log&responseType=json`;

      const options = {
        url,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: {
          description: note || "",
          type: "Entry",
          inTime: moment().format("YYYY-MM-DD hh:mm a"),
          picture: mediaId,
        },
      };

      const { data } = await request(options);
      //console.log(data, "data");

      if (data && data?.id) {
        const newNote = {
          id: data?.id,
          submittedBy: name,
          description: note,
          picture: mediaId,
          inTime: moment().format("YYYY-MM-DD hh:mm a"),
        };
        setDailyShiftReportData([newNote, ...dailyShiftReportData]);
        setNote("");
        setImage("");
      } else {
        alert("Note could not saved!.");
      }
      setIsLoading(false);
    } catch (err) {
      //console.log(err);
      setIsLoading(false);
    }
  };

  const onUpdateNote = async (item, text) => {
    setDailyShiftReportData(
      dailyShiftReportData.map((noteItem) =>
        noteItem.id === item.id ? { ...noteItem, description: text } : noteItem
      )
    );

    const url = `${API()}/action/shift?request=shift-log-update&responseType=json`;

    const options = {
      url,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: {
        id: item.id,
        description: text || "",
        type: "Entry",
        inTime: item.inTime,
        // "inTime": moment().format('YYYY-MM-DD hh:mm a')
      },
    };

    const { data } = await request(options);
    //console.log(data, "data");
  };

  const validHHMMstring = (str) =>
    /^([01]?[0-9]|2[0-3]):[0-5][0-9] [a|p][m]$/.test(str);
  return (
    <KeyboardAvoidingView behavior="padding">
      <ScrollView
        contentContainerStyle={styles.scrollInner}
        style={{ paddingBottom: 50 }}
      >
        <ButtonHeader
          location={currentLocation?.field2 || currentLocation?.id}
          title={currentButton.field2}
        />
        <Content>
          {shiftStarted && location.state?.shiftInfo && (
            <View>
              <SubTitle title={`Writer : ${name}`} />
              <SubTitle title={`Date : ${moment(date, "YYYY-MM-DD").format("dddd, MMM DD, YYYY")}`} />
              <SubTitle title={`Shift Hours : ${startTime} - ${endTime}`} />
            </View>
          )}
          {!shiftStarted && !location.state?.shiftInfo && (
            <>
              <SubTitle title="Date" />
              <View style={[styles.shadow, { marginBottom: 10 }]}>
                <View style={styles.container} onPress={() => { }}>
                  <View style={styles.info}>
                    <TextInput
                      style={styles.title}
                      value={date}
                      editable={false}
                    />
                  </View>
                </View>
              </View>
            </>
          )}
          {!shiftStarted && !location.state?.shiftInfo && (
            <>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: "45%" }}>
                  <SubTitle title="Start Time" />
                  <View style={[styles.shadow, { marginBottom: 10 }]}>
                    <View style={styles.container} onPress={() => { }}>
                      <View style={styles.info}>
                        <DatePicker
                          currentValue={startTime}
                          onChange={(value) => {
                            if (Platform.OS === "web") {
                              setStartTime(value);
                            } else {
                              setStartTime(moment(value).format("hh:mm a"));
                            }
                          }}
                        />
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ width: "45%" }}>
                  <SubTitle title="End Time" />
                  <View style={[styles.shadow, { marginBottom: 10 }]}>
                    <View style={styles.container} onPress={() => { }}>
                      <View style={styles.info}>
                        <DatePicker
                          currentValue={endTime}
                          onChange={(value) => {
                            if (Platform.OS === "web") {
                              setEndTime(value);
                            } else {
                              setEndTime(moment(value).format("hh:mm a"));
                            }
                          }}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </>
          )}
          {!location.state?.shiftInfo && (
            <View>
              {shiftStarted && (
                <>
                  {/* <Paragraph content={`${name} is on shift.`} /> */}

                  <View>
                    <SubTitle title={`Writer : ${name}`} />
                    <SubTitle title={`Date : ${moment(date, "YYYY-MM-DD").format("dddd, MMM DD, YYYY")}`} />
                    <SubTitle title={`Shift Hours : ${startTime} - ${endTime}`} />
                  </View>
                </>
              )}
              {!shiftStarted && (
                <>
                  <SubTitle title="Name" />
                  <View style={[styles.shadow, { marginBottom: 10 }]}>
                    <View style={styles.container} onPress={() => { }}>
                      <TextInput
                        editable={!shiftStarted}
                        style={[styles.title, { borderWidth: 0 }]}
                        value={name}
                        onChangeText={(text) => {
                          setName(text);
                        }}
                      />
                    </View>
                  </View>
                </>
              )}
              <Control
                loading={btnLoader}
                onPress={() => {
                  OnPressShift();
                }}
                title={
                  shiftStarted
                    ? t("Click To End Shift")
                    : t("Click To Start Shift")
                }
              />
              <Separator />
              {shiftStarted && (
                <>
                  <SubTitle title="Add new Note:" />
                  <View style={[styles.shadow, { marginBottom: 10 }]}>
                    <View style={styles.container} onPress={() => { }}>
                      <View style={styles.info}>
                        <TextInput
                          style={styles.title}
                          multiline
                          value={note}
                          onChangeText={(text) => {
                            setNote(text);
                          }}
                        />
                        {!isLoading ? (
                          <>
                            <Ionicons
                              onPress={() => {
                                setModal(true);
                              }}
                              style={styles.iconRight}
                              name={image ? "image" : "camera"}
                            />
                            <TouchableOpacity
                              onPress={() => {
                                onAddLog();
                              }}
                            >
                              <Text style={[styles.text, styles.link]}>
                                {t("Add")}
                              </Text>
                            </TouchableOpacity>
                          </>
                        ) : (
                          <ActivityIndicator size="small" color="#0000ff" />
                        )}
                      </View>
                    </View>
                  </View>
                </>
              )}

              <ModalTakePicture
                isVisible={modal}
                onHide={() => setModal(false)}
                image={image}
                onChooseImage={(pic) => setImage(pic)}
                // removeImage={removeImage}
                relaunch={() => {
                  setModal(false);
                  setTimeout(() => {
                    setModal(true);
                  }, 500);
                }}
              />
            </View>
          )}
          <SubTitle title="Notes:" />
          {isLoading && <ActivityIndicator size="small" color="#0000ff" />}
          {dailyShiftReportData?.map((item, index) => (
            <View style={styles.shadow}>
              <View
                style={styles.container}
              // onPress={() => { }}
              >
                <View style={styles.info}>
                  <TextInput
                    style={styles.title}
                    multiline
                    // editable={item.submittedBy == name}
                    editable={
                      item.submittedBy == name &&
                      moment(date, "YYYY-MM-DD").format("YYYY-MM-DD") ==
                      moment().format("YYYY-MM-DD")
                    }
                    value={item.description}
                    onChangeText={(text) => {
                      setDailyShiftReportData(
                        dailyShiftReportData.map((noteItem) =>
                          noteItem.id === item.id
                            ? { ...noteItem, description: text }
                            : noteItem
                        )
                      );
                    }}
                    onBlur={(e) => {
                      //console.log(e.nativeEvent.text);
                      const { text } = e.nativeEvent;
                      onUpdateNote(item, text);
                      // // if (text && dailyShiftReportData[index].description != text) {
                      // 	Alert.alert(
                      // 		"Confirmation",
                      // 		"Do you really want to Edit this note?",
                      // 		[
                      // 			{
                      // 				text: "Confirm",
                      // 				onPress: () => {
                      // 					onUpdateNote(item, text);
                      // 				},
                      // 				style: "cancel",
                      // 			},
                      // 		],
                      // 		{
                      // 			cancelable: true,
                      // 			onDismiss: () => { }
                      // 		}
                      // 	);
                      // // }
                    }}
                  />

                  {item.picture && item.picture != "" && (
                    <TouchableOpacity onPress={() => setImageViewModal(item)}>
                      <Ionicons style={styles.iconRight} name="image" />
                    </TouchableOpacity>
                  )}
                  {imageViewModal && item?.id == imageViewModal?.id && (
                    <ModalRead
                      isVisible={imageViewModal}
                      onHide={() => setImageViewModal(null)}
                      imageId={imageViewModal?.picture}
                    />
                  )}
                </View>

                <View style={[styles.info, { marginTop: 10 }]}>
                  <Text style={styles.name}>{item.submittedBy}</Text>
                  <Text style={styles.date}>
                    {moment(item.inTime, "YYYY-MM-DD hh:mm a").format(
                      "hh:mm a"
                    )}
                  </Text>
                </View>
              </View>
            </View>
          ))}
        </Content>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const mapStateToProps = createStructuredSelector({
  buttons: makeSelectButtons(),
  locations: makeSelectLocations(),
  userLocations: makeSelectUserLocation(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, memo)(DailyShiftReport);
