import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import * as Linking from 'expo-linking';
import styles from './styles';

import SubTitle from '../Content/SubTitle';
import Separator from '../Separator';

function ContactPerson({ contact }) {
	return (
		<View style={styles.container}>
			<SubTitle title={`${contact.firstName} ${contact.lastName}`} />
			{!!contact.company && <Text style={styles.text}>{contact.company}</Text>}
			{!!contact.roleText && (
				<Text style={styles.text}>{contact.roleText}</Text>
			)}
			{!!contact.phone && (
				<TouchableOpacity
					onPress={() => {
						try {
							Linking.openURL(`tel:${contact.phone}`);
						} catch (error) {
							//console.log(error, 'error');
						}
					}}
				>
					<Text style={[styles.text, styles.link]}>{contact.phone}</Text>
				</TouchableOpacity>
			)}
			{!!contact.email && (
				<TouchableOpacity
					onPress={() => Linking.openURL(`mailto:${contact.email}`)}
				>
					<Text style={[styles.text, styles.link]}>{contact.email}</Text>
				</TouchableOpacity>
			)}
			<View style={styles.separator}>
				<Separator />
			</View>
		</View>
	);
}

export default ContactPerson;
