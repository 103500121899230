import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
	shadow: {
		borderRadius: '$borderRadius',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 3,
		marginVertical:3
	},
	container: {
		borderRadius: '$borderRadius',
		backgroundColor: '$white',
		paddingHorizontal: '.5rem',
		paddingVertical: '.5rem',
		borderLeftWidth: 8,
		borderLeftColor: '$green',
	},
	info: {
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
	},
	iconLeft: {
		fontSize: '1rem',
		color: '$grey',
		marginRight: '1rem',
	},
	title: {
		// fontSize: '.8rem',
		color: '$text',
		fontFamily: '$fontMedium',
		textAlign: 'left',
		flex: 1,
		marginBottom:3,
		paddingHorizontal: '.3rem',
		paddingVertical: '.3rem',
	},
	date: {
		fontSize: '.6rem',
		color: '$text',
		fontFamily: '$fontBold',
		textAlign: 'left',
	},
	name: {
		fontSize: '.6rem',
		color: '$grey',
		fontFamily: '$fontMedium',
		textAlign: 'left',
	},
	iconRight: {
		fontSize: '1rem',
		color: '$grey',
		marginLeft: '0.5rem',
	},
	text: {
		color: '$text',
		fontFamily: '$fontRegular',
		paddingLeft: '1rem',
		marginBottom: '.2rem',
	},
	link: {
		color: '$blue',
		fontFamily: '$fontBold',
	},
	scrollInner: {
		flexGrow: 1,
		marginBottom: '2rem',
	},
});

export default styles;
