import env from '../constants/env';

const API = () => {
	// return 'https://devbiz.safebuildingsapp.ca';
	// return 'http://10.0.0.2:8080';
	// switch (env) {
	// 	case 'local':
	// return 'http://devbiz.safebuildingsapp.ca';
	// return 'https://devbiz.safebuildingsapp.ca';
	// 	case 'dev':	
	// 		return 'http://sb.mg2project.com';
	// 	case 'staging':
	// 		return 'http://sb.mg2project.com';
	// 	default:
	// return 'https://devbiz.safebuildingsapp.ca';
	return 'https://biz.safebuildingsapp.ca';
	// }
};

export default API;
