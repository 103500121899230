import EStyleSheet from "react-native-extended-stylesheet";

export const styles = EStyleSheet.create({
	modalContainer: {
		flex: 1,
		justifyContent: 'flex-end',
	},
	modal: {
		zIndex:100
	},
	controls: {
		marginBottom: '1rem',
		padding: '.5rem',
		backgroundColor: '$greyLight',
		borderRadius: '$borderRadius',
		// overflow: 'hidden',
	},
	control: {
		position: 'relative',
		borderRadius: '$borderRadius / 2',
		height: '3rem',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '$greyLight',
	},
	controlText: {
		textAlign: 'center',
		fontFamily: '$fontBold',
		color: '$white',
		fontSize: '.8rem',
	},
	modalView: {
		marginVertical: '1rem',
		backgroundColor: '$white',
		borderRadius: '$borderRadius',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.34,
		shadowRadius: 6.27,
		elevation: 10,
	},
	imageContainer: {
		width: '100%',
		aspectRatio: 1,
		backgroundColor: '$greyLightest',
		borderTopLeftRadius: '$borderRadius',
		borderTopRightRadius: '$borderRadius',
		overflow: 'hidden',
	},
	image: {
		width: '100%',
		aspectRatio: 1,
		backgroundColor: '$greyLightest',
	},
	imageWeb: {
		height: '75vh',
	},
	content: {
		paddingHorizontal: '2rem',
		paddingVertical: '2rem',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
	},
	roundControl: {
		alignItems: 'center',
		justifyContent: 'center',
	},
	bubble: {
		height: '3rem',
		width: '3rem',
		aspectRatio: 1,
		borderRadius: '1.5rem',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: '.5rem',
	},
	bubbleReplace: {
		backgroundColor: '$blue',
	},
	bubbleInsert: {
		backgroundColor: '$green',
	},
	bubbleClose: {
		backgroundColor: '$red',
		height: '1rem',
		width: '1rem',
		aspectRatio: 1,
		borderRadius: '1.5rem',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: '.5rem',
	},
	bubbleDelete: {
		backgroundColor: '$white',
		borderWidth: 1,
		borderColor: '$red',
	},
	icon: {
		fontSize: '1.5rem',
		color: '$white',
	},
	iconClose: {
		fontSize: '.8rem',
		color: '$white',
	},
	iconDelete: {
		color: '$red',
	},
	roundControlText: {
		fontFamily: '$fontMedium',
		fontSize: '.6rem',
		color: '$grey',
		textAlign: 'center',
		maxWidth: '3rem',
	},
});
