export const fr = {
	common: {
		emailAddress: 'Adresse Email',
		password: 'Mot de passe',
		logIn: 'Connexion',
		forgotPasswordControl: 'Vous avez oubliez votre mot de passe ?',
		version: 'Version',
		rememberMe: 'Se souvenir de moi',
		contactSafeBuildings: 'Contacter Safe Buildings',
		dateTime: '{{date, D MMMM YYYY - HH:mm}}',
		date: '{{date, D MMMM YYYY}}',
		welcomeX: 'Bienvenue {{name}}',
		sendUsAMessage: 'Nous envoyer un message',
		fullName: 'Nom complet',
		yourMessage: 'Votre message',
		sendYourMessage: 'Envoyez votre message',
		'Search in your locations': 'Chercher dans vos localisations',
		'Your locations (x)': 'Vos localisation ({{count}})',
		'Read more': 'Lire plus',
		'Terms and Conditions': 'Conditions d\'utilisations',
		Logout: 'Se deconnecer',
		helpPageTitle: 'Aide',
		helpPageSubTitle: 'Lorem ipsum',
		'Last update': 'Dernière mise à jour :',
		'Information Button': 'Bouton d\'information',
		Contact: 'Contact',
		EmergencyContacts: 'Emergency Contacts',
		Infrastructure: 'Infrastructure',
		DailyShiftReport: 'Daily Shift Report',
		Compliance: 'Conformité',
		Decline: 'Refuser',
		Accept: 'Accepter',
		'Credits to': 'Crédits à',
		scanNoMatchTitle: 'Aucune localisation trouvée',
		scanNoMatchMessage: 'Vous n\'avez aucune localisation qui correspond à ce QRcode',
		'Scan QR Code': 'Scanner le QR Code',
		scanQRSubTitle: 'Lorem ipsum',
		askPermissionCamera: 'Donner accés à la caméra',
		localAuthNoMatchTitle: 'Erreur de connexion',
		localAuthNoMatchMessage: 'Impossible de se connecter avec ce mode de connexion. Veuillez entrer vos identifiants manuellement.',
		'You do not have any location associated': 'Vous n\'avez aucune localisation associé',
		searchPageSubTitle: 'Lorem ipsum dolor sit amet',
		noInternetLoginTitle: 'Vous n\'avez pas de connection internet',
		noInternetLoginMessage: 'Veuillez vous reconnecter avant de pouvoir vous authentifier',
		'Search by': 'Rechercher par ...',
		'My account': 'Mon Compte',
		Question: 'Question {{count}}',
	},
};

export const en = {
	common: {
		emailAddress: 'Email Address',
		password: 'Password',
		logIn: 'Log in',
		forgotPasswordControl: 'Forgot password?',
		version: 'Version',
		rememberMe: 'Remember me',
		contactSafeBuildings: 'Contact Safe Buildings',
		dateTime: '{{date, D MMMM YYYY - HH:mm}}',
		date: '{{date, D MMMM YYYY}}',
		welcomeX: 'Welcome {{name}}',
		sendUsAMessage: 'Send us a message',
		fullName: 'Full name',
		phone: 'Phone',
		yourMessage: 'Your message',
		sendYourMessage: 'Send your message',
		'Search in your locations': 'Search in your locations',
		'Your locations (x)': 'Your locations ({{count}})',
		'Read more': 'Read more',
		'Terms and Conditions': 'Terms and Conditions',
		Logout: 'Logout',
		helpPageTitle: 'Help',
		helpPageSubTitle: 'Scroll down to find your solution.',
		'Last update': 'Last update:',
		'Information Button': 'Information Button',
		Contact: 'Contact',
		EmergencyContacts: 'Emergency Contacts',
		Infrastructure: 'Overview',
		DailyShiftReport: 'Daily Shift Report',
		Compliance: 'Compliance',
		Decline: 'Decline',
		Accept: 'Accept',
		'Credits to': 'Credits to',
		scanNoMatchTitle: 'No location found',
		scanNoMatchMessage: 'You don\'t have any location that match this QRcode',
		'Scan QR Code': 'Scan QR Code',
		scanQRSubTitle: 'Please scan QR code to be directed to your building.',
		askPermissionCamera: 'Give permission to access the camera',
		localAuthNoMatchTitle: 'Connexion Error',
		localAuthNoMatchMessage: 'You can\'t connect right now with this method. Please enter your credentials manually.',
		'You do not have any location associated': 'You do not have any location associated',
		searchPageSubTitle: 'Lorem ipsum dolor sit amet',
		noInternetLoginTitle: 'You do not have access to internet',
		noInternetLoginMessage: 'Please reconnect to internet before trying to authenticate',
		'Search by': 'Search by...',
		'My account': 'My account',
		newPasswordNoMatchTitle: 'The password does not match',
		newPasswordNoMatchMessage: 'Please enter the same password twice',
		currentNewPasswordNoMatchTitle: 'The current password is not correct',
		currentNewPasswordNoMatchMessage: 'Please enter your password correctly',
		newPasswordServerErrorTitle: 'Server error',
		newPasswordServerErrorMessage: 'We\'re currently having trouble to connect to the server, please try again later.',
		newPasswordSecurityErrorTitle: 'Not enough secure',
		newPasswordSecurityErrorMessage: 'Your password must at least have a capital letter, a number and a special character',
		newPasswordSetTitle: 'Password set',
		newPasswordSetMessage: 'Your new password is set',
		modifyPasswordHint: 'Your password must at least have a capital letter, a number and a special character.',
		myAccountUserUpdatedTitle: 'Your account has been saved',
		myAccountUserUpdatedMessage: '',
		messageSubmittedTitle: 'Message sent',
		messageSubmittedMessage: 'Your message has been sent',
		explainNfcTitle: 'Instructions NFC',
		explainNfcMessage: 'Be sure that the NFC functionality is activated on your device. Proceed by approaching your device as close as possible from the NFC tag. The Control will turn green when the corresponding NFC is approached.',
		explainNfcDoneTitle: 'NFC already scanned',
		explainNfcDoneMessage: 'This NFC is already scanned.',
		nfcErrorTitle: 'NFC Error',
		nfcErrorMessage: 'NFC is not supported on this device, you won\'t be able to submit this checklist',
		nfcNoMatchTitle: 'No match for this NFC Tag',
		nfcNoMatchMessage: 'The NFC tag you have scanned does not match any question in this checklist',
		nfcErrorEnableTitle: 'NFC not enabled',
		nfcErrorEnableMessage: 'You need to enable NFC on your device in order to complete this checklist',
		nfcErrorMissingTitle: 'NFC Missing',
		nfcErrorMissingMessage: 'It seems like you have not scanned NFC tags in question {{missing}}. Would you like to proceed without using NFC tags?',
		Question: 'Question {{count}}',
		'NFC Tag': 'NFC Tag ({{name}})',
		grantAccessCameraRollTitle: 'Access denied',
		grantAccessCameraRollMessage: 'You have to give the permission to access the camera and camera roll in order to add a picture to this question',
		missingNFCValidationTitle: 'Missing NFC Validation',
		missingNFCValidationMessage: 'You have to scan the NFC Tag required in: Question {{number}}',
		missingResponseValidationTitle: 'Missing response',
		missingResponseValidationMessage: 'You have to give your response in: Question {{number}}',
		checklistSendSuccessTitle: 'Checklist completed',
		checklistSendSuccessMessage: 'Your checklist has been sent, thank you.',
		checklistNotActiveTitle: 'Checklist not actived',
		checklistNotActiveMessage: 'This checklist is not actived, you cannot use it',
		SwipeNotifDescription: 'swipe a notification from left to right to see the available actions.',
		forgotPasswordSentTitle: 'Check your email',
		forgotPasswordSentMessage: 'We sent an email to {{email}} with instructions to reset your password.',
		forgotPasswordSentCheckEmail: 'Open mail application',
		forgotPasswordSentOK: 'OK',
		forgotPasswordParagraph: 'Please enter your email address in the input then press send. If your email is recognized you will receive an email with instructions.',
		forgotPassword: 'Forgot password ?',
		loginNoMatchTitle: 'We are sorry',
		loginNoMatchMessage: 'We don’t recognize {{username}} or {{username}} with this password combination. Please confirm your email and password or contact us at support@safebuildings.ca.',
		buttonWithoutChecklistTitle: 'There is no checklist to load',
		buttonWithoutChecklistMessage: 'There is no checklist associated with this button, please add a checklist to this button to proceed.',
	},
};
