export const CHANGE_INPUT = 'containers/LoginBox/CHANGE_INPUT';
export const SUBMIT_FORM = 'containers/LoginBox/SUBMIT_FORM';
export const SUBMIT_AUTH = 'containers/LoginBox/SUBMIT_AUTH';
export const AUTH_SUCCESS = 'containers/LoginBox/AUTH_SUCCESS';
export const FORM_SUBMITTED_SUCCESS = 'containers/LoginBox/FORM_SUBMITTED_SUCCESS';
export const FORM_SUBMITTED_FAIL = 'containers/LoginBox/FORM_SUBMITTED_FAIL';
export const ERROR_LOGIN = 'containers/LoginBox/ERROR_LOGIN';
export const UNMOUNT = 'containers/LoginBox/UNMOUNT';
export const TRYLOCALAUTH = 'containers/LoginBox/TRYLOCALAUTH';
export const SUBMIT_SSO = 'containers/LoginBox/SUBMIT_SSO';
export const SSO_COMPLETED = 'containers/LoginBox/SSO_COMPLETED';
export const CANLOCALAUTH = 'containers/LoginBox/CANLOCALAUTH';
export const RESET_STATE = "RESET_STATE"; 
