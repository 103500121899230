import React, { memo, useEffect, useState } from 'react';
import { Image, Linking, Text, TouchableOpacity, View } from 'react-native';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import he from 'he';

import { useInjectReducer } from '../../utils/injectReducer'; // eslint-disable-line

import Title from '../../components/Content/Title';
import Logo from '../../assets/images/logo-black.png';
import Map from '../../assets/images/map.png';
import styles from './styles';

import reducer from './reducer';
import makeSelectDashboard from './selectors';
import { makeSelectUser } from '../../providers/UserProvider/selectors';
import Search from '../Search';
import { Ionicons } from '@expo/vector-icons';
import login from '../../services/login';
import Modal from '../../services/modal';
import SubTitle from '../../components/Content/SubTitle';
import EStyleSheet from 'react-native-extended-stylesheet';
import { ScrollView } from 'react-native';
import Paragraph from '../../components/Content/Paragraph';
import * as Location from 'expo-location';

const key = 'dashboard';

const aspectRatio = 3.5;

function Dashboard({ user }) {
	useInjectReducer({ key, reducer });
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		const checkPermissions = async () => {
		  try {
			let { status } = await Location.getBackgroundPermissionsAsync();
			const user = await login.getUser();
			// console.log(user, "useruseraa");
			if (user?.allowGeofencing !== null && user?.allowGeofencing && status !== 'granted') {
			  setShowModal(true);
			}
		  } catch (err) {
			console.warn(err);
		  }
		};
	
		checkPermissions();
	  }, []);

	const onAccept = async () => {
		try {
			setShowModal(false)

			let { status } = await Location.requestBackgroundPermissionsAsync();
		} catch (error) {

		}
	}
	return (
		<>
			{showModal &&
				<Modal
					backdropColor={EStyleSheet.value('$white')}
					style={{ margin: 20 }}
					backdropOpacity={0.95}
					isVisible={showModal}
					onBackdropPress={setShowModal}
					onBackButtonPress={setShowModal}
					animationIn="zoomIn"
					animationOut="zoomOut"
					
					scrollHorizontal={true}
				>
					<ScrollView>
						<View style={{ padding: 10, marginTop: 50, alignItems: "center" }}>
							<SubTitle>Use Your Location</SubTitle>
							<Paragraph content={`SafeBuilding collects location data to enable Geofence even when the app is closed or not in use.`}></Paragraph>
							<Paragraph content={`SafeBuilding may collect and use your device’s location information to provide specific features or services. This information can include precise or approximate location data, such as GPS coordinates or information derived from nearby Wi-Fi networks and cell towers.`}></Paragraph>

							<TouchableOpacity
								onPress={() => {
									try {
										Linking.openURL(`https://www.safebuildings.ca/legal-privacy`);
									} catch (error) {
										//console.log(error, 'error');
									}
								}}
							>
								<Paragraph content={`For more detailed information please visit at https://www.safebuildings.ca/legal-privacy.`}></Paragraph>
							</TouchableOpacity>
							<Image style={{ height: 100, width: 200, marginTop: 10 }} source={Map}></Image>
							<View style={{ marginTop: EStyleSheet.value('5rem'), justifyContent: "space-between", flexDirection: "row" }}>
								<TouchableOpacity style={{ marginHorizontal: EStyleSheet.value('4rem') }} onPress={() => { setShowModal(false) }}>
									<SubTitle>DENY</SubTitle>
								</TouchableOpacity>

								<TouchableOpacity style={{ marginHorizontal: EStyleSheet.value('4rem') }} onPress={async () => { await onAccept() }}>
									<SubTitle>ACCEPT</SubTitle>
								</TouchableOpacity>
							</View>
						</View>
					</ScrollView>
				</Modal>
			}
			<Search
				header={(
					<>
						<Image
							style={[styles.logo, { aspectRatio }]}
							source={Logo}
							resizeMode="contain"
						/>
						<Title title={`Welcome ${he.decode(user?.firstName ?? '')}`} />
						{/* <View style={{ flexDirection: "row",marginTop:-10 }}>
						<Ionicons name="md-thunderstorm-outline" style={styles.searchIcon} />
						<Text style={styles.tmp}>{`  Thunder Storm`}</Text>
						<Text style={styles.tmp}>{`        26°C`}</Text>
					</View> */}

					</>
				)}
			/>
		</>
	);
}

const mapStateToProps = createStructuredSelector({
	dashboard: makeSelectDashboard(),
	user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(
	withConnect,
	memo,
)(Dashboard);
