import React from 'react';
import { Text } from 'react-native';
import Constants from 'expo-constants';

import styles from './styles';
import env, { isInDevelopment } from '../../constants/env';
import API from '../../config/api';

function Version() {
	if (API()=="https://biz.safebuildingsapp.ca") {
		return (
			<Text style={styles.version}>
			{`version : ${Constants.manifest.version}`}
			</Text>
		);
	}
	return (
		<Text style={styles.version}>r
			{`'version' : ${Constants.manifest.version} -Beta`}
		</Text>
	);
}

export default Version;
