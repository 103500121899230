import { Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
	card: {
		borderRadius: '$borderRadius / 2',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		backgroundColor: '$white',
		padding: '1rem - 2px',
		marginBottom: '1rem',
		marginHorizontal: '1rem',
		borderWidth: 2,
		borderColor: 'transparent',
	},
	cardCurrent: {
		borderWidth: 2,
		borderColor: '$blue',
	},
	cardUrgent: {
		borderWidth: 2,
		borderColor: '$red',
	},
	cardNoNFC: {
		borderWidth: 2,
		borderColor: 'orange',
	},
	cardHeader: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '2rem',
	},
	title: {
		fontFamily: '$fontBold',
		color: '$text',
		fontSize: '.8rem',
	},
	subTitle: {
		fontFamily: '$fontMedium',
		color: '$text',
		marginBottom: '1rem',
		fontSize: '.8rem',
		lineHeight: '1.2rem',
	},
	viewPicture: {
		paddingVertical: '.5rem',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: '.5rem',
	},
	viewPictureIcon: {
		color: '$blue',
		fontSize: '1.2rem',
		marginRight: '.5rem',
	},
	viewPictureText: {
		fontFamily: '$fontBold',
		color: '$blue',
		fontSize: '.8rem',
	},
	urgentPill: {
		backgroundColor: '$red',
		height: '2rem',
		justifyContent: 'flex-start',
		alignItems: 'center',
		borderRadius: '1rem',
		marginBottom: '1rem',
		width: '50%',
		flexDirection: 'row',
	},
	noNFCPill: {
		backgroundColor: 'orange',
		height: '2rem',
		justifyContent: 'flex-start',
		alignItems: 'center',
		borderRadius: '1rem',
		marginBottom: '1rem',
		width: '85%',
		flexDirection: 'row',
	},
	urgentIcon: {
		color: '$white',
		fontSize: '1.25rem',
		paddingLeft: '1rem',
		paddingRight: '.5rem',
	},
	urgentText: {
		color: '$white',
		fontFamily: '$fontBold',
		fontSize: '1rem',
	},
	image: {
		height: 100,
		width: 100,
		aspectRatio: 0.9,
		borderRadius: Platform.OS === 'ios' ? '$borderRadius' : 0,
		padding: 10
	},
});

export const modalReadStyles = EStyleSheet.create({
	modalContainer: {
		marginVertical: '1rem',
		backgroundColor: '$white',
		borderRadius: '$borderRadius',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.34,
		shadowRadius: 6.27,
		elevation: 10,
	},
	modalImageContainer: {
		width: '100%',
		aspectRatio: 1,
		backgroundColor: '$greyLightest',
		borderTopLeftRadius: '$borderRadius',
		borderTopRightRadius: '$borderRadius',
		overflow: 'hidden',
	},
	modalImage: {
		width: '100%',
		aspectRatio: 1,
		backgroundColor: '$greyLightest',
	},
	modalImageWeb: {
		height: '75vh',
	},
	modalContent: {
		paddingHorizontal: '1rem',
		paddingVertical: '2rem',
	},
	modalCloseContainer: {
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		width: '100%',
		marginTop: '2rem',
		elevation: 1,
		zIndex: 1,
	},
	modalClose: {
		elevation: 1,
		zIndex: 1,
	},
	modalCloseIcon: {
		fontSize: '2rem',
		color: '$grey',
	},
	bubbleClose: {
		backgroundColor: '$red',
		height: '1rem',
		width: '1rem',
		aspectRatio: 1,
		borderRadius: '1.5rem',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: '.5rem',
	},
	roundControl: {
		alignItems: 'center',
		justifyContent: 'center',
	},
	iconClose: {
		fontSize: '.8rem',
		color: '$white',
	},
});

export const modalTakePictureStyles = EStyleSheet.create({
	modalContainer: {
		flex: 1,
		justifyContent: 'flex-end',
	},
	modal: {
		zIndex:100
	},
	controls: {
		marginBottom: '1rem',
		padding: '.5rem',
		backgroundColor: '$greyLight',
		borderRadius: '$borderRadius',
		// overflow: 'hidden',
	},
	control: {
		position: 'relative',
		borderRadius: '$borderRadius / 2',
		height: '3rem',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '$greyLight',
	},
	controlText: {
		textAlign: 'center',
		fontFamily: '$fontBold',
		color: '$white',
		fontSize: '.8rem',
	},
	modalView: {
		marginVertical: '1rem',
		backgroundColor: '$white',
		borderRadius: '$borderRadius',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.34,
		shadowRadius: 6.27,
		elevation: 10,
	},
	imageContainer: {
		width: '100%',
		aspectRatio: 1,
		backgroundColor: '$greyLightest',
		borderTopLeftRadius: '$borderRadius',
		borderTopRightRadius: '$borderRadius',
		overflow: 'hidden',
	},
	image: {
		width: '100%',
		aspectRatio: 1,
		backgroundColor: '$greyLightest',
	},
	imageWeb: {
		height: '75vh',
	},
	content: {
		paddingHorizontal: '2rem',
		paddingVertical: '2rem',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
	},
	roundControl: {
		alignItems: 'center',
		justifyContent: 'center',
	},
	bubble: {
		height: '3rem',
		width: '3rem',
		aspectRatio: 1,
		borderRadius: '1.5rem',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: '.5rem',
	},
	bubbleReplace: {
		backgroundColor: '$blue',
	},
	bubbleInsert: {
		backgroundColor: '$green',
	},
	bubbleClose: {
		backgroundColor: '$red',
		height: '1rem',
		width: '1rem',
		aspectRatio: 1,
		borderRadius: '1.5rem',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: '.5rem',
	},
	bubbleDelete: {
		backgroundColor: '$white',
		borderWidth: 1,
		borderColor: '$red',
	},
	icon: {
		fontSize: '1.5rem',
		color: '$white',
	},
	iconClose: {
		fontSize: '.8rem',
		color: '$white',
	},
	iconDelete: {
		color: '$red',
	},
	roundControlText: {
		fontFamily: '$fontMedium',
		fontSize: '.6rem',
		color: '$grey',
		textAlign: 'center',
		maxWidth: '3rem',
	},
});


export const modalTakeMultiPictureStyles = EStyleSheet.create({
	modalContainer: {
	  marginBottom: '0.5rem',
	  position: 'relative',
	  paddingTop: '1rem',
	},
	modal: {
	  flexDirection: 'row',
	  borderWidth: 1,
	  borderColor: '$grey',
	  borderRadius: 5,
	  paddingHorizontal: 10,
	  color: '$text',
	  fontFamily: '$fontMedium',
	  fontSize: '.8rem',
	  paddingVertical: '1rem',
	  // justifyContent: 'center',
	},
	placeholder: {
	  position: 'absolute',
	  zIndex: 2,
	  color: '$grey',
	  fontFamily: '$fontMedium',
	  backgroundColor: '$white',
	  left: 10,
	  top: 2,
	  padding: 5,
	  alignSelf: 'center',
	},
	controls: {
	  flex: 1,
	  flexDirection: 'row',
	  padding: '.5rem',
	  borderRadius: '$borderRadius',
	  justifyContent: 'space-evenly',
	},
	control: {
	  position: 'relative',
	  borderRadius: '$borderRadius / 2',
	  height: '3rem',
	  alignItems: 'center',
	  justifyContent: 'center',
	  // backgroundColor: '$greyLight',
	},
	controlText: {
	  textAlign: 'center',
	  // fontFamily: '$fontBold',
	  color: '$text',
	  fontSize: '.8rem',
	},
	modalView: {
	  flexDirection: 'row',
	  marginVertical: '.5rem',
	  padding: '1rem',
	  backgroundColor: '$white',
	  borderRadius: '$borderRadius',
	  shadowColor: '#000',
	  shadowOffset: {
		width: 0,
		height: 5,
	  },
	  shadowOpacity: 0.34,
	  shadowRadius: 6.27,
	  elevation: 10,
	  width: '100%',
	},
	imageContainer: {
	  flexDirection: 'row',
	  // backgroundColor: '$greyLightest',
	  borderTopLeftRadius: '$borderRadius',
	  borderTopRightRadius: '$borderRadius',
	  // justifyContent: 'center',
	},
	imageButton: {
	  width: '4rem',
	  height: '4rem',
	  margin: 5,
	  justifyContent:"center"
	},
	image: {
	  width: '100%',
	  height: '100%',
	  resizeMode: 'cover',
	},
	imageWeb: {
	  height: '75vh',
	},
	content: {
	  // width: '100%',
	  // paddingHorizontal: '0.3rem',
	  // paddingVertical: '2rem',
	  flexDirection: 'row',
	  justifyContent: 'space-between',
	  // alignItems: 'flex-start',
	  // backgroundColor: '$greyLightest',
	},
	roundControl: {
	  alignItems: 'center',
	  justifyContent: 'center',
	},
	bubble: {
	  height: '3rem',
	  width: '3rem',
	  aspectRatio: 1,
	  borderRadius: '1.5rem',
	  alignItems: 'center',
	  justifyContent: 'center',
	  marginBottom: '.5rem',
	  marginHorizontal: '0.5rem',
	},
	bubbleReplace: {
	  backgroundColor: '$text',
	},
	bubbleInsert: {
	  backgroundColor: '$green',
	},
	bubbleDelete: {
	  backgroundColor: '$white',
	  borderWidth: 1,
	  borderColor: '$red',
	},
	icon: {
	  fontSize: '1.5rem',
	  color: '$white',
	},
	removeImageIcon: {
	  fontSize: '.9rem',
	  fontWeight: '600',
	  color: '$text',
	  position: 'absolute',
	  right: -5,
	  top: -5,
	  padding: 1,
	  borderRadius: 50,
	  backgroundColor: '$white',
  
	  shadowColor: '$text',
	  shadowOffset: {
		width: 0,
		height: 12,
	  },
	  shadowOpacity: 0.25,
	  shadowRadius: 3.84,
	  elevation: 5,
	},
	iconDelete: {
	  color: '$red',
	},
	roundControlText: {
	  fontFamily: '$fontMedium',
	  fontSize: '.6rem',
	  color: '$grey',
	  textAlign: 'center',
	  maxWidth: '3rem',
	},
  
	fullImage: {
	  height: '100%',
	  // position: 'absolute',
	  // flex: 1,
	  justifyContent: 'center',
	  alignItems: 'center',
	},
  
	iconButton: {
	  position: 'absolute',
	  right: '0.5rem',
	  top: '1rem',
	  zIndex: 10,
	},
	modalIcon: {
	  // position: 'absolute',
	  fontSize: '1.5rem',
	},
	imageModal: {
	  justifyContent: 'center',
	  alignItems: 'center',
	  flex: 1,
	},
  });

export default styles;
