import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
	logo: {
		// width: '50%',
		height: '2.5rem',
		marginBottom: '1rem',
		justifyContent: 'flex-start',
	},
	searchIcon: {
		fontSize: '2rem',
		color: '$red',
		marginBottom: '1rem',
		// marginRight: '1rem',
	},
	tmp: {
		// color: '$blueLight',
		color: '$red',
		fontFamily: '$fontBold',
		fontSize: '1.2rem',
		marginBottom: '1rem',
		// marginTop: 5,
	},
});
export default styles;
