const EULA = `<p class=MsoBodyText align=center style='text-align:center'><b><span
style='font-size:11.0pt'>TERMS OF USE</span></b></p>

<p class=MsoBodyText style='text-align:justify'><span style='font-size:11.0pt'>Please
read these Terms of Use (“Terms”) carefully before using Safebuildings’
website, applications, products and services (collectively, the “Service”).
The Service is provided to you by Safe Buildings Tech Inc. (“Safebuildings”,
“we”, “our” or “us”). Your access to and use of the Service is subject to these
Terms. By downloading, installing, accessing and/or using the Service you agree
to be bound by these Terms whether or not you have read them. <b>If you do not
agree to these Terms, do not download, install, access or use the Service.</b> </span></p>

<p class=MsoBodyText style='text-align:justify'><span style='font-size:11.0pt'>Safebuildings
may at its sole discretion modify these Terms at any time and such
modifications will be effective immediately upon being posted on the Service<b>.</b>
Please check these Terms periodically for any modifications. <b>If you do not
agree to any modifications of these Terms, you must immediately stop using the
Service.</b> Your continued use of the Service following posting of any
modifications will indicate your acceptance of any such modified Terms.</span></p>

<p class=IPStandard1>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>Availability</span></p>

<p class=IPStandard2 style='text-align:justify'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>You may use the Service only if you can
form a binding contract with Safebuildings, and only in compliance with these
Terms and all applicable laws. If you are using the Services on behalf of a
company, organization or other entity (each, an “Organisation”), “you” shall
include such Organization and you represent and warrant to us that you have the
authority to bind such Organization to these Terms.</span></p>

<p class=IPStandard2 style='text-align:justify'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>In order to access and use the Services,
you may be required to register and set up an account with us. When you
register for an account, you must provide us with accurate and complete
information. You are solely responsible for maintaining the confidentiality of
your account login credentials, including your password. You are solely
responsible for all activities that occur under your account, including
unauthorized use by a third party. If you are using the Services on behalf of
an Organization, each individual user of the Organization will create their own
account and you agree not to share your account within the Organization. </span></p>

<p class=IPStandard2 style='text-align:justify'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>If you are using Safebuildings’ mobile
application, we hereby grant you a non-exclusive, time-limited,
non-transferable, non-sub-licensable, revocable license to install and use
Safebuildings’ mobile application on your mobile device, subject to these
Terms.</span></p>

<p class=IPStandard2 style='text-align:justify'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>Your use of the Service must comply with
these Terms and all of our policies.</span></p>

<p class=IPStandard2 style='margin-left:0cm;text-align:justify;text-indent:
0cm'><span lang=EN>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN style='font-size:11.0pt'>We reserve the right to
refuse access to the Service to anyone for any reason at any time.</span></p>

<p class=IPStandard1>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>Prohibited Activities</span></p>

<p class=IPStandard2 style='margin-left:0cm;text-align:justify;text-indent:
0cm'><span lang=EN style='font-size:11.0pt'>Safebuildings reserves the right at
all times to prohibit activities that may damage its legal interests,
commercial reputation and/or goodwill, or that of its affiliates or its users.
As such, all conduct that violates law, regulation, or is deemed inappropriate
(as determined by Safebuildings in its sole discretion), whether or not
expressly mentioned in these Terms, is prohibited. Without limiting the
foregoing, you shall NOT: </span></p>

<p class=IPStandard2 style='text-align:justify'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span lang=EN style='font-size:11.0pt'>use the Service for any illegal
or unauthorized purpose;</span></p>

<p class=IPStandard2 style='text-align:justify'><span lang=EN>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN style='font-size:11.0pt'>post or otherwise make
available on or via the Service Content (as defined below) that is
discriminatory, unlawful, infringing, or otherwise inappropriate;</span></p>

<p class=IPStandard2 style='text-align:justify'><span lang=EN>(c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN style='font-size:11.0pt'>post or otherwise make
available on or via the Service third-party information you are obligated to
keep confidential or that you do not have the authority to do so;</span></p>

<p class=IPStandard2 style='text-align:justify'><span lang=EN>(d)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN style='font-size:11.0pt'>solicit, obtain or use the
login credentials of another user of the Service;</span></p>

<p class=IPStandard2 style='text-align:justify'><span lang=EN>(e)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN style='font-size:11.0pt'>interfere or disrupt the
Service or servers or networks connected to the Service, including by
transmitting any worms, viruses, spyware, malware or any other code of a
destructive or disruptive nature;</span></p>

<p class=IPStandard2 style='text-align:justify'><span lang=EN>(f)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt'>use, modify, reproduce,
distribute, sell, license, or otherwise use the Service (or any Service Content
accessible therein) without our permission</span><span lang=EN
style='font-size:11.0pt'>; and</span></p>

<p class=IPStandard2 style='text-align:justify'><span lang=EN>(g)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN style='font-size:11.0pt'>crawl, scrape, cache, frame,
mirror or otherwise access any content on the Service via automated means.</span></p>

<p class=IPStandard1>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>User Content</span></p>

<p class=IPStandard2 style='text-align:justify'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>You are solely responsible for your
conduct and any content, including images, comments, and other materials or
information that you upload, post or otherwise make available on or via the
Service (collectively, the “User Content”). You represent and warrant that the
User Content does not and will not violate any law or infringe, misappropriate
or otherwise violate the rights of any third-party, including </span><span
lang=EN style='font-size:11.0pt'>without limitation, privacy rights,
copyrights, trade-mark and/or other intellectual property rights.</span></p>

<p class=IPStandard2 style='text-align:justify'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>Safe Buildings does not claim ownership
of any User Content. You grant Safebuildings a non-exclusive, worldwide,
royalty-free, fully paid, sublicensable, fully transferable, perpetual,
irrevocable license to use, process, transmit, host, store, transfer, display,
reproduce, modify, and disclose the User Content (a) for the purpose of
providing the services to you and our other users, and (b) in a form that does
not identify you as the source of the User Content, for our business purposes
including to develop and improve Safebuildings products and services. You
represent and warrant that you have the right to grant all permissions and
licenses provided in these Terms.</span></p>

<p class=IPStandard2 style='text-align:justify'><span lang=EN>(c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN style='font-size:11.0pt'>We may, but are not
obligated to, modify, remove, block, and/or monitor any User Content or user
account that we believe in our sole discretion violates these Terms.</span></p>

<p class=IPStandard2 style='text-align:justify'><span lang=EN>(d)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt'>Some portions of the Services
provide fire safety plans and other building essential and emergency
information and related checklists (collectively, “Service Content”)
electronically to you through Safebuildings’ mobile application or software
accessible by an Internet browser accessing our website. </span><span lang=EN
style='font-size:11.0pt'>If you are providing content to us to make available
to other users through the Service as Service Content, additional terms and
conditions apply.</span></p>

<p class=IPStandard1>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>Intellectual Property </span></p>

<p class=IPStandard2 style='text-align:justify'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span lang=EN style='font-size:11.0pt'>The Service may incorporate,
contain or otherwise use content (including Service Content), software or other
technology owned or licensed by Safebuildings (the &quot;Safebuildings
IP&quot;). The Safebuildings IP may be protected by copyright, trade-mark,
patent, trade secret and other laws, and, as between you and Safebuildings,
Safebuildings retains all rights in the Safebuildings IP and the Service. You
will not remove, alter or conceal any copyright, trade-mark, service mark or
other proprietary rights notices incorporated in or accompanying the Safebuildings
IP and you will not reproduce, modify, adapt, prepare derivative works based
on, display, publish, distribute, transmit, broadcast, sell, license or
otherwise exploit the Safebuildings IP.</span></p>

<p class=IPStandard2 style='text-align:justify'><span lang=EN>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN style='font-size:11.0pt'>If you choose to send us
comments, ideas, suggestions, or other feedback (“Feedback”), which is not
subject to other provisions in these Terms, you agree that Safebuildings is
free to use any such Feedback, without any restriction, liability or
compensation to you of any kind. Safebuildings’ acceptance of such Feedback
does not waive any rights to use similar or related Feedback previously known,
developed, or otherwise obtained by Safebuildings from other sources.</span></p>

<p class=IPStandard1 style='margin-top:12.0pt'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>Privacy Policy</span></p>

<p class=IPStandard1 style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:0cm;text-indent:0cm'><span style='font-size:11.0pt;
font-weight:normal'>The personal information practices of the Service are
governed by the Privacy Policy at </span><a
href="https://www.safebuildings.ca/privacy-policy/"><span style='font-size:
11.0pt;font-weight:normal'>https://www.safebuildings.ca/privacy-policy/</span></a><span
style='font-size:11.0pt;font-weight:normal'>. Please review this Privacy
Policy. </span></p>

<p class=IPStandard1>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>Third-Party Content</span></p>

<p class=MsoBodyText style='text-align:justify'><span style='font-size:11.0pt'>The
Service and Service Content may contain third-party content that is not owned
by Safebuildings. We do not verify, endorse, or assume any responsibility for,
any such third-party content. If you access any third-party content from the
Service, you do so at your own risk and you agree that Safebuildings will have
no liability arising from your use of or access to any third-party content.
All correspondence and/or dealings with a third-party found through the Service
are solely between you and the third party.</span></p>

<p class=IPStandard1>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>Modifications</span></p>

<p class=IPStandard2 style='margin-left:0cm;text-align:justify;text-indent:
0cm'><span style='font-size:11.0pt'>We reserve the right to update, upgrade or
otherwise modify the Service or your access to the Service at our sole
discretion, without notice, and without liability to you. You agree that these
Terms will automatically apply to any such update, upgrade or other
modification.</span></p>

<p class=IPStandard1>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>Termination</span></p>

<p class=IPStandard2 style='margin-left:0cm;text-align:justify;text-indent:
0cm'><span style='font-size:11.0pt'>We reserve the right to modify or terminate
the Service or your access to the Service at our sole discretion, without
notice, and without liability to you. Upon termination, all licenses or other
rights granted to you in these Terms will immediately terminate. You remain
bound by Sections 3, 4, 6, and 8 to 16 of these Terms.</span></p>

<p class=IPStandard1>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>Indemnity</span></p>

<p class=MsoBodyText style='text-align:justify'><span style='font-size:11.0pt'>You
agree to indemnify, defend and hold harmless Safebuildings and its affiliates
and any of their officers, directors, employees, agents and other
representatives (collectively, the “Safebuildings Parties”), from and against
any claims, suits, proceedings, disputes, demands, liabilities, damages,
losses, costs and expenses, including, without limitation, reasonable legal and
accounting fees (including costs of defense of claims, suits or proceedings
brought by third parties), in any way related to (a) your access to or use of
the Service (and the content provided therein), (b) User Content, (c) your
breach of any of these Terms, (d) your violation of any third-party right,
including without limitation, any intellectual property rights, (e) your
violation of any laws, rules, regulations, codes, statutes, ordinances or
orders of any governmental and quasi-governmental authorities, or (f) any
misrepresentation made by you.</span></p>

<p class=IPStandard1>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>Disclaimers</span></p>

<p class=MsoBodyText style='text-align:justify'><span style='font-size:11.0pt'>The
Service, its contents, and any functionalities and services offered or
contained therein are provided on an “as is” basis and on an “as available”
basis without any representations, warranties, guarantees or conditions of any
kind, whether express, legal or implied.</span></p>

<p class=MsoBodyText style='text-align:justify'><span style='font-size:11.0pt'>SAFEBUILDINGS
SPECIFICALLY DISCLAIMS ANY AND ALL REPRESENTATIONS, WARRANTIES, GUARANTEES AND
CONDITIONS, INCLUDING WITHOUT LIMITATION, REPRESENTATIONS, WARRANTIES,
GUARANTEES AND CONDITIONS OF MERCHANTABLE QUALITY, QUALITY OR FITNESS FOR A
PARTICULAR PURPOSE, NON-INFRINGEMENT OF THIRD-PARTY RIGHTS, ERROR-FREE OR
UNINTERRUPTED SERVICE, ACCURACY, AVAILABILITY, RELIABILITY, SECURITY, CURRENCY
AND COMPLETENESS ARISING FROM OR RELATING TO THE SERVICE, ITS CONTENT, USER
CONTENT OR ANY FUNCTIONALITIES OR SERVICES PROVIDED THEREIN. </span></p>

<p class=MsoBodyText style='text-align:justify'><span style='font-size:11.0pt'>Safebuildings
does not verify, endorse, and takes no responsibility and assume no liability
for, any Service Content. You understand and agree that you may be exposed to
Service Content that is inaccurate, incomplete, incorrect or otherwise unsuited
to your purpose.</span></p>

<p class=IPStandard1>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>Limitation of Liability</span></p>

<p class=MsoBodyText style='text-align:justify'><span style='font-size:11.0pt'>TO
THE MAXIMUM EXTENT PERMITTED BY LAW, THE SAFEBUILDINGS PARTIES SHALL NOT BE
LIABLE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY
OR ANY OTHER LEGAL THEORY, FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL
OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED
DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOOD-WILL, OR OTHER
INTANGIBLE LOSSES, RESULTING FROM OR RELATED TO (A) USER CONTENT; (B) YOUR
ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICE (AND THE CONTENTS
THEREIN); (C) ANY CONDUCT OR CONTENT OF ANY THIRD-PARTY ON THE SERVICE,
INCLUDING WITHOUT LIMITATION, ANY SERVICE CONTENT OF OTHER USERS OR THIRD
PARTIES; OR (D) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR
CONTENT. IN NO EVENT SHALL THE SAFEBUILDINGS PARTIES’ AGGREGATE LIABILITY TO
YOU FOR ALL CLAIMS RELATED TO THE SERVICE EXCEED THE GREATER OF ONE HUNDRED
CANADIAN DOLLARS (CAD$100.00) OR THE AMOUNTS PAID BY YOU TO SAFEBUILDINGS FOR
THE SERVICE IN THE LAST TWELVE (12) MONTHS PRIOR TO THE ACTION GIVING RISE TO
THE LIABLITY.</span></p>

<p class=IPStandard1>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>Arbitration</span></p>

<p class=MsoBodyText style='text-align:justify'><span style='font-size:11.0pt'>For
any dispute you have with any of the Safebuildings Parties, you agree to first
contact us and attempt to resolve the dispute with us informally. If
Safebuildings has not been able to resolve the dispute with you informally, we
each agree that any claim, dispute, or controversy (excluding claims for
injunctive or other equitable relief) arising out of or in connection with or
relating to these Terms are to be arbitrated and finally resolved, pursuant to
the National Arbitration Rules of the ADR Institute of Canada, Inc. Unless
Safebuildings agrees otherwise, the place of arbitration shall be Toronto,
Ontario, Canada. The language of the arbitration shall be English. Nothing in
this Section shall prevent either party from seeking injunctive or other
equitable relief from the courts for matters related to intellectual property
or unauthorized access to the Service. You hereby consent and submit to the
jurisdiction of the courts of the Province of Ontario and the Federal Court of
Canada in any such action or proceeding and agree not to commence any action or
proceeding except in Toronto, Ontario, Canada. ALL CLAIMS MUST BE BROUGHT IN
THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
PURPORTED CLASS OR REPRESENTATIVE PROCEEDING, AND, UNLESS WE AGREE OTHERWISE,
THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU AGREE
THAT, BY ENTERING INTO THESE TERMS, YOU AND SAFEBUILDINGS ARE EACH WAIVING THE
RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.</span></p>

<p class=IPStandard1>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>Governing Law and Jurisdiction</span></p>

<p class=IPStandard2 style='margin-left:0cm;text-align:justify;text-indent:
0cm'><span style='font-size:11.0pt'>The interpretation, validity, effect and
enforcement of the Terms are governed by the laws of the Province of Ontario
and the laws of Canada applicable therein. These laws apply to the access and
use of the Services by you, notwithstanding any conflicts of laws principles,
your domicile, residency or physical location. </span></p>

<p class=IPStandard2 style='margin-left:0cm;text-align:justify;text-indent:
0cm'><span style='font-size:11.0pt'>The Service is controlled and operated from
Canada, and we make no representations that the Service is appropriate or
available for use in other locations. The Service is intended for use only in
jurisdictions where the Service may lawfully be provided for use.</span></p>

<p class=IPStandard1>14.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>Assignment</span></p>

<p class=IPStandard2 style='margin-left:0cm;text-align:justify;text-indent:
0cm'><span style='font-size:11.0pt'>These Terms, and any rights and licenses
granted hereunder, may not be transferred or assigned by you, but may be
assigned by Safebuildings without restriction. Any attempted transfer or
assignment in violation of this Section shall be null and void.</span></p>

<p class=IPStandard1>15.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>Entire Agreement</span></p>

<p class=MsoBodyText style='text-align:justify'><span style='font-size:11.0pt'>These
Terms, together with any policies referred to herein, and any amendments and
any additional agreements you may enter into with Safebuildings in connection
with the Service, shall constitute the entire agreement between you and
Safebuildings concerning the Service and supersede any prior terms you have
with Safebuildings regarding the Service. If any provision of these Terms is
deemed invalid, then that provision will be limited or eliminated to the
minimum extent necessary, and the remaining provisions of these Terms will
remain in full force and effect.</span></p>

<p class=IPStandard1>16.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-size:11.0pt'>No Waiver</span></p>

<p class=MsoBodyText style='text-align:justify'><span style='font-size:11.0pt'>No
waiver of any term of these Terms shall be deemed a further or continuing
waiver of such term or any other term, and Safebuildings’ failure to assert any
right or provision under these Terms shall not constitute a waiver of such
right or provision. </span></p>`;

export default EULA;
