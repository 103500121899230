import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
	container: {
	  backgroundColor: '$white',
	  // alignSelf: 'center',
	  marginHorizontal: '0.5rem',
	  marginBottom: '4rem',
	  marginTop: '1rem',
	  borderRadius: 10,
  
	//   shadowColor: '$dark',
	  shadowOffset: {
		width: 0,
		height: 2,
	  },
	  shadowOpacity: 0.25,
	  shadowRadius: 3.84,
	  elevation: 5,
	  flex: 1,
	},
	heading: {
		paddingTop: '2rem',
		paddingHorizontal: '1rem',
	},
	content: {
		padding: '1rem',
	},
	scrollInner: {
		flexGrow: 1,
		// paddingBottom: '4rem',
	},
});

export default styles;
