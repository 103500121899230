import React from 'react';
import { Switch, Route, withRouter } from '../../services/router';
import LocationHome from './LocationHome';
import LocationContact from './LocationContact';
import LocationInfra from './LocationInfra';
import ButtonParent from './Buttons/ButtonParent';
import ButtonField from './Buttons/ButtonField';
import ButtonChecklist from './Buttons/ButtonChecklist';
import Checklist from './Checklist';
import Document from './Document';
import IncidentReport from './IncidentReport';
import IncidentReportHistory from './IncidentReportHistory';
import DailyShiftReport from './DailyShiftReport';
import DailyShiftReportHistory from './DailyShiftReportHistory';

function Location({ match }) {
	return (
		<>
			<Switch>
				<Route exact path={`${match.path}/button/:buttonId/incidentReportHistory/:buttonId`} component={IncidentReportHistory} />
				<Route exact path={`${match.path}/incidentReportHistory/:buttonId`} component={IncidentReportHistory} />
				<Route exact path={`${match.path}/incidentReport/:buttonId`} component={IncidentReport} />
				<Route exact path={`${match.path}/button/:buttonId/dailyShiftReportHistory/:buttonId`} component={DailyShiftReportHistory} />
				<Route exact path={`${match.path}/dailyShiftReportHistory/:buttonId`} component={DailyShiftReportHistory} />
				<Route exact path={`${match.path}/dailyShiftReport/:buttonId`} component={DailyShiftReport} />
				<Route exact path={`${match.path}/button/:buttonId`} component={ButtonParent} />
				<Route exact path={`${match.path}/button/:buttonId/field`} component={ButtonField} />
				<Route exact path={`${match.path}/button/:buttonId/checklist`} component={ButtonChecklist} />
				<Route exact path={`${match.path}/contact`} component={LocationContact} />
				<Route exact path={`${match.path}/infrastructure`} component={LocationInfra} />
				<Route exact path={[`${match.path}/checklist/:checklistId`, `${match.path}/checklist/:checklistId/:responseId`]} component={Checklist} />
				<Route exact path={`${match.path}/document/:documentId`} component={Document} />
				<Route component={LocationHome} />
			</Switch>
		</>
	);
}

export default withRouter(Location);
