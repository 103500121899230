/*
 *
 * LanguageProvider actions
 *
 */

import * as TaskManager from 'expo-task-manager';
import * as Location from 'expo-location';
import { Platform } from 'react-native';
import moment from 'moment';
import { CHANGE_USER_LOCATION, REMOVE_USER_LOCATION } from './constants';
import API from '../../config/api';
import request from '../../utils/request';
import store from '../../store';

const LOCATION_TRACKING = 'SB-location-tracking';

function changeLocation(location) {
	return {
		type: CHANGE_USER_LOCATION,
		location,
	};
}

function removeLocation(length) {
	return {
		type: REMOVE_USER_LOCATION,
		length,
	};
}

export async function stopLocation() {
	if (Platform.OS !== 'web') {
		await TaskManager.isTaskRegisteredAsync(LOCATION_TRACKING).then((tracking) => {
			//console.log(tracking, 'tracking');
			if (tracking) {
				Location.stopLocationUpdatesAsync(LOCATION_TRACKING);
			}
		});
	}
}

export const startLocationTracking = async () => {
	await Location.startLocationUpdatesAsync(LOCATION_TRACKING, {
		accuracy: Location.Accuracy.Low,
		timeInterval: 10000,
		distanceInterval: 10,
		foregroundService: {
			notificationTitle: 'Location',
			notificationBody: 'SB tracking you',
		},
	});
	const hasStarted = await Location.hasStartedLocationUpdatesAsync(
		LOCATION_TRACKING,
	);
	// setLocationStarted(hasStarted);
	//console.log('tracking started?', hasStarted);
};

export async function getCurrentLocation(dispatch) {
	//console.log(dispatch, 'dispatch');
	if (Platform.OS !== 'web') {
		Location.requestForegroundPermissionsAsync().then(async (res) => {
			if (res.status === 'granted') {
				await startLocationTracking();
				TaskManager.defineTask(LOCATION_TRACKING, async ({ data, error }) => {
					if (error) {
						//console.log('LOCATION_TRACKING task ERROR:', error);
						return;
					}
					if (data) {
						// alert('positionposition');
						const { locations } = data;
						if (locations.length) {
							const lat = locations[0].coords.latitude;
							const long = locations[0].coords.longitude;
							const location = {
								lat,
								lng: long,
								locationTime: moment().format('YYYY-MM-DDTHH:mm'),
							};
								dispatch(changeLocation(location));
							
							//console.log(
							// 	locations[0].coords,
							// 	'LOC==>',
							// 	`${new Date(Date.now()).toLocaleString()}: ${lat},${long}`,
							// );
						}
					}
				});
			}
		});
	}
}

// export default getCurrentLocation;
// export default changeLocale;
