import React, { useEffect, memo, useState } from 'react';
import {
	View, KeyboardAvoidingView, ScrollView
} from 'react-native';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Alert from '../../../services/alert';
import { useHistory, useLocation, useParams } from '../../../services/router';
import ButtonHeader from '../../../components/ButtonHeader';
import { t } from '../../../services/i18n';
import Content from '../../../components/Content/Content';
import Control from '../../../components/Control';
import ChecklistCard from '../../../components/ChecklistCard';
import styles from './styles';
import ENUM_TYPE_CHECKLIST from '../../../constants/enumTypeChecklist';
import ModalSaveExit from './ModalSaveExit';
import { useInjectReducer } from '../../../utils/injectReducer'; /* eslint-disable-line */
import reducer from './reducer';
import makeSelectLocationChecklist from './selectors';
import {
	setModal,
	setNfcSupport,
	setNfcEnabled,
	resetNfc,
	pollingNfc,
	reset,
	haveSendResponse,
	setCurrent,
} from './actions';
import {
	makeSelectChecklists,
	makeSelectChecklistsInProgress,
	makeSelectChecklistsLoadingSendResponse,
	makeSelectResponses,
} from '../../../providers/ChecklistProvider/selectors';
import {
	setQuestionDeficiency,
	setQuestionResponse,
	setQuestionNFC,
	setQuestionPic,
	setQuestionNfcValidation,
	sendResponse,
	deleteQuestionNfc,
	addQuestionNfc,
	editQuestionNfc,
	setQuestionNfcScanning,
} from '../../../providers/ChecklistProvider/actions';
import DescriptionRead from './DescriptionRead';
import Separator from '../../../components/Separator';
import HtmlParser from '../../../components/HtmlParser';
import API from '../../../config/api';
import axios from 'axios';

// Key for the reducer of this page
const key = 'locationchecklist';

function Checklist({
	loading,
	checklist,
	handleSetModal,
	handleReset,
	checklists,
	inProgress,
	handleSetQuestionDeficiency,
	handleSetQuestionNfcScanning,
	handleSetQuestionResponse,
	handleSetQuestionNFC,
	handleSetQuestionPic,
	handleSetQuestionNfcValidation,
	handleSendResponse,
	handleHaveSendResponse,
	responses,
	handleDeleteQuestionNfc,
	handleAddQuestionNfc,
	handleEditQuestionNfc,
	handleSetCurrent,
}) {
	// Inject the reducer of this page
	useInjectReducer({ key, reducer });
	// Use the history, location and params from the router component
	const history = useHistory();
	const location = useLocation();
	const { checklistId, responseId } = useParams();
	const [questions, SetQuestions] = useState([]);
	const [currentResponse, SetCurrentResponse] = useState({});
	// Select current checklist
	const currentChecklist = checklists.find((el) => Number(el.id) === Number(checklistId));
	// Select current in progress
	// In progress only have the response of the current Checklist
	// Can be null if we don't have started to respond the current checklist
	let currentInProgress = null;
	if (inProgress.length > 0) {
		currentInProgress = inProgress.find((el) => Number(el.checklist) === Number(checklistId));
	}
	// Add an helper to know if this checklist have already been started
	const isInProgress = !!currentInProgress || false;
	useEffect(() => {
		const buildQuestionsArray = async () => {
		  // build question array
		  const { questions: rawQuestions } = JSON.parse(currentChecklist.field8);
		  let questions = [];
		  let i = 0;
	  
		  if (location.state.type !== ENUM_TYPE_CHECKLIST.READ || location.state.type !== ENUM_TYPE_CHECKLIST.VIEW) {
			//console.log(location.state.type, "location.state.type");
			rawQuestions.forEach((question, i) => {
			  questions.push({ ...question, i, key: `${i}${question.name}` });
			});
		  } else {
			rawQuestions.forEach((question, i) => {
			  questions.push({ ...question, qPictures: question?.qPictures.map(() => "https://img.freepik.com/premium-vector/system-software-update-upgrade-concept-loading-process-screen-vector-illustration_175838-2182.jpg"), i, key: `${i}${question.name}` });
			});
		  }
	  
		  //console.log(location.state.type, ENUM_TYPE_CHECKLIST.READ);
		  // If it's type read, then create the
		  let currentResponse = {};
		  if (responseId && location.state.type === ENUM_TYPE_CHECKLIST.READ) {
			questions = [];
			currentResponse = responses.find((response) => Number(response?.id ?? -1) === Number(responseId));
			const responsesParse = JSON.parse(currentResponse?.field3)?.questions ?? [];
			responsesParse.forEach((response, i) => {
			  questions.push({ ...response, i, key: `${i}${response.name}` });
			});
		  }
		  SetCurrentResponse(currentResponse);
		  SetQuestions(questions);
		  questions = [];
	  
		  if (!(responseId && location.state.type === ENUM_TYPE_CHECKLIST.READ) && location.state.type !== ENUM_TYPE_CHECKLIST.VIEW) {
			for (const question of rawQuestions) {
			  const qPictures = question?.qPictures ? JSON.parse(question.qPictures) : [];
			  const images = [];
			  for (const image of qPictures) {
				const source = `${API()}/action/getSecureFiles?id=${image.id.toString()?.split('&')[0]}`;
				try {
				  const res = await axios.get(source, {
					responseType: 'blob',
				  });
				  const reader = new window.FileReader();
				  reader.readAsDataURL(res.data);
				  reader.onload = () => {
					images.push({ ...image, base64: reader.result });
				  };
				} catch (error) {
				  images.push({ ...image, base64: null });
				  console.warn(error);
				}
			  }
			  questions.push({ ...question, qPictures: images, i, key: `${i}${question.name}` });
			  i++;
			};
			//console.log(questions, "questions");
			SetQuestions(questions);
		  }
		};
	  
		buildQuestionsArray();
	  }, []);
	//   }, [currentChecklist, location.state.type, responseId, responses]);
	// This function always wait for an NFC tag in order to listen to his event
	async function listenTagEvent(NFCRead) {
		// Check if message match with an NFC Tag in questions
		const NFCQuestion = questions.find((question) => {
			if (question.NFCValidation && question.NFCTag) {
				for (let index = 0; index < question.NFCData.length; index += 1) {
					const tagCode = question.NFCData[index];
					if (tagCode.toString() === NFCRead.toString()) {
						return true;
					}
				}
			}
			return false;
		});
		if (NFCQuestion) {
			// If it match set the NFC response to true for this question
			// And give a UX Feedback => Vibrate
			handleSetQuestionNFC(true, NFCQuestion.i, checklistId);
		} else {
			// If it doesnt match, send an alert to the user
			Alert.alert(
				t('nfcNoMatchTitle'), t('nfcNoMatchMessage'),
			);
		}
	}

	// This effect is trigger by a change in the checklistId
	useEffect(() => {
		// Cleanup function
		return () => {
		  handleReset();
		};
	  }, [checklistId]);
	// This effect print a success to the user when he have succesfully submited a response
	useEffect(() => {
		if (!currentInProgress && checklist.haveSendResponse) {
			Alert.alert(
				t('checklistSendSuccessTitle'), t('checklistSendSuccessMessage'),
				[
					{ text: t('OK'), onPress: history.goBack },
				],
				{ cancelable: false },
			);
		}
	}, [currentInProgress, checklist.haveSendResponse]);

	useEffect(() => {
		if (loading.error) {
			Alert.alert(
				t('newPasswordServerErrorTitle'), t('newPasswordServerErrorMessage'),
				[
					{ text: t('OK'), onPress: history.goBack },
				],
			);
		}
	}, [loading.error]);
	useEffect(() => {
		// Reset current selected card to 0 when arriving on the page
		handleSetCurrent(0);
		// Reset current selected card to 0 when leaving the page... just in case :D
		return () => {
			handleSetCurrent(0);
		};
	}, []);
	function isQuestionCompleted(question) {
		const { NFCValidation, responseType, i } = question;
		const NFCStored = currentInProgress?.[`question${i}nfc`] ?? false;
		if (NFCValidation) {
			if (!NFCStored) {
				return false;
			}
		}

		let toggly = null;
		let text = '';
		switch (responseType) {
			case 'text':
				text = currentInProgress?.[`question${i}`] ?? '';
				if (text === '') {
					return false;
				}
				break;
			case 'confirm':
				toggly = currentInProgress?.[`question${i}`] ?? null;
				if (toggly === null) {
					return false;
				}
				break;
			case 'yes-no':
				toggly = currentInProgress?.[`question${i}`] ?? null;
				if (toggly === null) {
					return false;
				}
				break;
			case 'dropdown':
				text = currentInProgress?.[`question${i}`] ?? '';
				if (text === '') {
					return false;
				}
				break;
			default:
				break;
		}

		return true;
	}
	// Listen to the currentInProgress change to check the active question
	useEffect(() => {
		for (let index = 0; index < questions.length; index += 1) {
			const question = questions[index];
			if (!isQuestionCompleted(question)) {
				handleSetCurrent(index);
				break;
			}
		}
	}, [currentInProgress]);
	// This function take care of generating Question Card
	function generateQuestion(question) {
		// take what we need from the question
		const { responseType, key: itemKey, i } = question;
		// Declare response content
		const deficiencyMessage = currentInProgress?.[`question${question.i}deficiency`] ?? '';
		const nfcReason = currentInProgress?.[`question${question.i}nfcReason`] ?? '';
		const deficiency = currentInProgress?.[`question${question.i}activeDeficiency`] ?? false;
		const scanningNFC = currentInProgress?.[`question${question.i}activeNfc`] ?? false;
		let toggly = null;
		let text = '';
		switch (responseType) {
			case 'text':
				text = currentInProgress?.[`question${question.i}`] ?? '';
				break;
			case 'confirm':
				toggly = currentInProgress?.[`question${question.i}`] ?? null;
				break;
			case 'yes-no':
				toggly = currentInProgress?.[`question${question.i}`] ?? null;
				break;
			case 'dropdown':
				text = currentInProgress?.[`question${question.i}`] ?? '';
				break;
			default:
				break;
		}
		// Return the node
		return (
			<ChecklistCard
				// key={itemKey}
				question={question}
				nfc={currentInProgress?.[`question${question.i}nfc`] ?? false}
				setNfc={listenTagEvent}
				nfcTapped={listenTagEvent}
				deficiency={deficiency}
				setDeficiency={(active) => handleSetQuestionDeficiency(active, deficiencyMessage, i, checklistId)}
				scanningNFC={scanningNFC}
				setScanningNFC={(active) => handleSetQuestionNfcScanning(active, nfcReason, i, checklistId)}
				toggly={toggly}
				setToggly={(value) => handleSetQuestionResponse(responseType === 'confirm' ? (value || null) : value, responseType, i, checklistId)}
				image={currentInProgress?.[`question${question.i}pic`] ?? false}
				setImage={(pic) => handleSetQuestionPic(pic, i, checklistId)}
				text={text}
				setText={(value) => handleSetQuestionResponse(value, responseType, i, checklistId)}
				deficiencyMessage={deficiencyMessage}
				setDeficiencyMessage={(value) => handleSetQuestionDeficiency(deficiency, value, i, checklistId)}
				nfcReason={nfcReason}
				setNfcReason={(value) => handleSetQuestionNfcScanning(scanningNFC, value, i, checklistId)}
				setNfcValidation={(value) => handleSetQuestionNfcValidation(value, i, currentChecklist)}
				addNfc={(title, data) => handleAddQuestionNfc(title, data, i, currentChecklist)}
				editNfc={(title, data, nfcIndex) => handleEditQuestionNfc(title, data, nfcIndex, i, currentChecklist)}
				deleteNfc={(value) => handleDeleteQuestionNfc(value, i, currentChecklist)}
				current={checklist.current === i}
			/>
		);
	}
	// This function look for any question not completly satisfied
	function formValidation() {
		// Assume that the form is valid until we prove it's not
		let formIsValid = true;
		let isNFCFilled = true;
		// Loop over every question
		let nfcArr = [];
		let resArr = [];
		questions.forEach(({ NFCValidation, i }) => {
			// Declars vars with the possibility that we haven't started to respond to any question yet
			const haveInProgressNFC = isInProgress ? !!currentInProgress[`question${i}nfc`] : false;
			let haveResponse = false;
			if (isInProgress) {
				switch (typeof currentInProgress?.[`question${i}`]) {
					case 'boolean':
						haveResponse = true;
						break;
					case 'string':
						if (currentInProgress?.[`question${i}`].length > 0) {
							haveResponse = true;
						}
						break;
					default:
						break;
				}
			}
			// Verify that this question is asking for NFC and that we already have scanned the NFC
			if (NFCValidation && !haveInProgressNFC) {
				// Alert.alert(t('missingNFCValidationTitle'), t('missingNFCValidationMessage', { number: i + 1 }));
				// formIsValid = false;
				nfcArr.push(i + 1);
				isNFCFilled = false;
			}
			// Verify that we have a response for this question
			if (!haveResponse) {
				// Alert.alert(t('missingResponseValidationTitle'), t('missingResponseValidationMessage', { number: i + 1 }));
				resArr.push(i + 1);
				formIsValid = false;
			}
		});
		if (resArr.length > 0) {
			Alert.alert(t('missingResponseValidationTitle'),`You have to give your response in: Question ${resArr.join(",")}`);
		}
		if (!isNFCFilled && formIsValid) {
			if (formIsValid) {
				const confirmed = window.confirm(`It seems like you have not scanned NFC tags in question ${nfcArr.join(', ')}. Would you like to proceed without using NFC tags?`);
				if (confirmed) {
					// Tell that we have tried to send the response
					handleHaveSendResponse();
					// Send it
					handleSendResponse(currentInProgress);
				}
			}
		}

		// If after the check the form is still valid
		if (formIsValid && isNFCFilled) {
			// Tell that we have tried to send the response
			handleHaveSendResponse();
			// Send it
			handleSendResponse(currentInProgress);
		}
	}

	return (
		<KeyboardAvoidingView behavior="position">
			<ScrollView contentContainerStyle={styles.scrollInner}>
				<ButtonHeader
					title={t('Checklist')}
					context={currentChecklist.field2}
					icon="md-pin"
				/>
				{location.state?.type === ENUM_TYPE_CHECKLIST.READ && (
					<DescriptionRead currentResponse={currentResponse} />
				)}
				{!!currentChecklist?.field4 && (
					<View style={styles.descriptionChecklist}>
						<Separator small />
						<HtmlParser html={currentChecklist.field4} />
					</View>
				)}
				<Content noPaddingHorizontal>
					{questions.map((question) => generateQuestion(question))}

					{(location.state?.type === ENUM_TYPE_CHECKLIST.READ || location.state?.type === ENUM_TYPE_CHECKLIST.VIEW) && (
						<View style={styles.controls}>
							<Control theme="alt" onPress={history.goBack} title={t('Back to checklist')} />
						</View>
					)}
					{(location.state?.type === ENUM_TYPE_CHECKLIST.CREATE || location.state?.type === ENUM_TYPE_CHECKLIST.UPDATE) && (
						<View style={styles.controls}>
							<Control theme="alt" onPress={() => handleSetModal(true)} title={t('Save and exit')} />
							<Control loading={loading.loading} onPress={formValidation} title={t('Submit')} />
						</View>
					)}
				</Content>
			</ScrollView>
			<ModalSaveExit isVisible={checklist.modal} onHide={() => handleSetModal(false)} />
		</KeyboardAvoidingView>
	);
}

const mapStateToProps = createStructuredSelector({
	checklist: makeSelectLocationChecklist(),
	checklists: makeSelectChecklists(),
	inProgress: makeSelectChecklistsInProgress(),
	loading: makeSelectChecklistsLoadingSendResponse(),
	responses: makeSelectResponses(),
});

export function mapDisptachToProps(dispatch) {
	return {
		handleSetModal: (value) => dispatch(setModal(value)),
		handleSetNfcSupport: (value) => dispatch(setNfcSupport(value)),
		handleSetNfcEnabled: (value) => dispatch(setNfcEnabled(value)),
		handleResetNfc: () => dispatch(resetNfc()),
		handleReset: () => dispatch(reset()),
		handlePollingNfc: () => dispatch(pollingNfc()),
		handleSetQuestionDeficiency: (active, message, questionIndex, checklistId) => dispatch(setQuestionDeficiency(active, message, questionIndex, checklistId)),
		handleSetQuestionNfcScanning: (active, message, questionIndex, checklistId) => dispatch(setQuestionNfcScanning(active, message, questionIndex, checklistId)),
		handleSetQuestionResponse: (response, responseType, questionIndex, checklistId) => dispatch(setQuestionResponse(response, responseType, questionIndex, checklistId)),
		handleSetQuestionNFC: (value, questionIndex, checklistId) => dispatch(setQuestionNFC(value, questionIndex, checklistId)),
		handleSetQuestionPic: (pic, questionIndex, checklistId) => dispatch(setQuestionPic(pic, questionIndex, checklistId)),
		handleSendResponse: (response) => dispatch(sendResponse(response)),
		handleHaveSendResponse: () => dispatch(haveSendResponse()),
		handleSetQuestionNfcValidation: (value, questionIndex, checklist) => dispatch(setQuestionNfcValidation(value, questionIndex, checklist)),
		handleDeleteQuestionNfc: (value, questionIndex, checklist) => dispatch(deleteQuestionNfc(value, questionIndex, checklist)),
		handleAddQuestionNfc: (title, data, questionIndex, checklist) => dispatch(addQuestionNfc(title, data, questionIndex, checklist)),
		handleEditQuestionNfc: (title, data, nfcIndex, questionIndex, checklist) => dispatch(editQuestionNfc(title, data, nfcIndex, questionIndex, checklist)),
		handleSetCurrent: (current) => dispatch(setCurrent(current)),
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDisptachToProps,
);

export default compose(memo, withConnect)(Checklist);
