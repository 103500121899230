import React from 'react';
import HTML from 'react-native-render-html';
import EStyleSheet from 'react-native-extended-stylesheet';

import { Dimensions, View } from 'react-native';
import * as Linking from 'expo-linking';
import Separator from '../Separator';

function HtmlParser({ html, noMargin = false }) {
	const transformHtml = html.replace(
		'text-decoration: underline;',
		'text-decoration-line: underline;',
	);
	return (
		<View style={{ marginBottom: noMargin ? 0 : EStyleSheet.value('2rem') }}>
			<HTML
				html={transformHtml}
				imagesMaxWidth={
					Dimensions.get('window').width - EStyleSheet.value('2rem')
				}
				onLinkPress={(_, href) => {
					try {
						Linking.openURL(href);
					} catch (error) {
						//console.log(error,"error");
					}
				}}
				classesStyles={{
					'[style=text-decoration]': {
						textDecorationLine: 'underline',
					},
				}}
				tagsStyles={
					{
						// p: {
						// 	fontFamily: 'Montserrat-Regular',
						// 	color: '#34446E',
						// 	marginBottom: 12,
						// },
						// i: {
						// 	fontFamily: 'Montserrat-Regular',
						// },
						// b: {
						// 	fontFamily: 'Montserrat-Bold',
						// },
						// li: {
						// 	fontFamily: 'Montserrat-Regular',
						// },
						// ul: {
						// 	marginBottom: 12,
						// },
						// ol: {
						// 	marginBottom: 12,
						// },
						// strong: {
						// 	fontFamily: 'Montserrat-Bold',
						// },
						// img: {
						// 	marginBottom: 12,
						// },
						// em: {
						// 	fontFamily: 'Montserrat-Regular',
						// 	fontStyle: 'italic',
						// 	marginBottom: 12,
						// },
						// a: {
						// 	fontFamily: 'Montserrat-Bold',
						// 	color: '#0E6AD2',
						// },
						// h1: {
						// 	fontFamily: 'Montserrat-Bold',
						// 	marginBottom: 12,
						// },
						// h2: {
						// 	fontFamily: 'Montserrat-Bold',
						// 	marginBottom: 12,
						// },
						// h3: {
						// 	fontFamily: 'Montserrat-Medium',
						// 	marginBottom: 12,
						// },
						// h4: {
						// 	fontFamily: 'Montserrat-Medium',
						// 	marginBottom: 12,
						// },
						// h5: {
						// 	fontFamily: 'Montserrat-Medium',
						// 	marginBottom: 12,
						// },
						// div: {
						// 	marginBottom: 2,
						// },
					}
				}
				renderers={{
					hr: () => <Separator />,
				}}
			/>
		</View>
	);
}

export default HtmlParser;
