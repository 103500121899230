import React, { memo, useEffect, createRef, useState } from 'react';

import { Text, View, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer } from '../../../utils/injectReducer'; // eslint-disable-line
import { useInjectSaga } from '../../../utils/injectSaga'; // eslint-disable-line
import { t } from '../../../services/i18n';

import reducer from './reducer';
import saga from './saga';
import makeSelectLoginBox from './selectors';
import { makeSelectNetworkIsConnected } from '../../../selectors';
import {
	changeInput, submitForm, unmount, tryLocalAuth, canLocalAuth, submitAuth, retreiveUser, submitSSO, ssoCompleted,
} from './actions';

import styles from './styles';
import Title from '../../../components/Content/Title';
import Input from '../../../components/Form/Input';
import Control from '../../../components/Control';
import login from '../../../services/login';
import Version from '../Version';
import Alert from '../../../services/alert';


import * as WebBrowser from 'expo-web-browser';
import * as AuthSession from 'expo-auth-session';

import AUTHAPI from '../../../config/authAPI';
import API from '../../../config/api';

const key = 'loginbox';

WebBrowser.maybeCompleteAuthSession();
const useProxy = true;
// const redirectUri = AuthSession.makeRedirectUri({
// 	useProxy,
// });
const redirectUri = AuthSession.makeRedirectUri({
	scheme: 'safebuildings',
	path: 'auth'
})

function LoginBox({
	loginBox,
	handleChangeInput,
	handleSubmitForm,
	handleSubmitAuth,
	handleSSOCompleted,
	history,
	handleUnmount,
	handleTryLocalAuth,
	handleSubmitSSO,
	handleCanLocalAuth,
	isConnected,
}) {
	useInjectReducer({ key, reducer });
	useInjectSaga({ key, saga });
	//console.log(redirectUri, "redirectUri");
	const secondInput = createRef();
	const [isAuthShow, setIsAuthShow] = useState(false);

	// const baseUrl=“http://localhost:8080”;
	const clientId = '83fac9cf-8754-4217-a746-2531a7527680';
	const baseUrl = `${AUTHAPI()}`;
	// Endpoint
	const discovery = {
		authorizationEndpoint: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize`,
		tokenEndpoint: `https://login.microsoftonline.com/common/oauth2/v2.0/token`
	};

	const {
		username, error, password, remember, loading, sent, terms, tryAuth, localAuthSupport, loginType, loginDetails, sso_Completed
	} = loginBox;

	// If there's no internet connection, then print an alert
	function checkConnectionBeforeSending(user, pass, remem) {
		//console.log(arguments)
		if (isConnected) {
			if (loginType == "oauth2") {
				// promptAsync();
			}
			else if (loginType == "local") {
				handleSubmitForm(user, pass, remem);
			}
			else {
				handleSubmitAuth(user, "", remem);
			}
		} else {
			Alert.alert(t('noInternetLoginTitle'), t('noInternetLoginMessage'));
		}
	}
	useEffect(() => {
		if (loginType == "oauth2" && loginDetails.auth_url != '' && loginDetails.token_url != '') {
			promptAsync();
			setTimeout(() => {
				handleSSOCompleted();
			}, 100);
		}
	}, [loginType, loginDetails])
	useEffect(() => {
		if (loginType == "oauth2" && loginDetails.auth_url != '' && loginDetails.token_url != '' && sso_Completed) {
			//console.log(loginDetails, "loginDetails", loginDetails.auth_url);
			promptAsync();
		}
	}, [sso_Completed])
	// If there's already an user in the securestorage, then populate the field
	async function setCreds() {
		const creds = await login.getCreds();
		if (creds) {
			handleChangeInput(creds.username, 'username');
		}
	}

	useEffect(() => {
		// on load, set the creds
		setCreds();
		return () => {
			// on unmount
			handleUnmount();
		};
	}, []);

	useEffect(() => {
		// If the form is correclyt sent
		if (sent) {
			// Send the user to the terms page if he haven't already validated it
			if (!terms) {
				// history.push('/', { page: 'terms' });
				history.push('/', { page: 'otpbox' });
			} else {
				history.push('/dashboard');
			}
		}
	}, [sent]);

	useEffect(() => {
		// On error change for match username/password, trigger an alert
		if (error.match) {
			Alert.alert(t('loginNoMatchTitle'), t('loginNoMatchMessage', { username }));
		}
	}, [error.match]);

	const [authRequest, result, promptAsync] = AuthSession.useAuthRequest(
		{
			clientId,
			redirectUri,
			scopes: ["openid", "email"],
			usePKCE: true,
			responseType: 'code',
			// extraParams: loginDetails.extraParams
		},
		{
			authorizationEndpoint: loginDetails.auth_url,
			tokenEndpoint: loginDetails.token_url
		},
	);


	const decodeIdToken = (idToken) => {
		// Example function to decode an ID token (specific to your identity provider)
		// You may need to use a library or service to decode the ID token
		// For example, Auth0 provides a library for this purpose
		// https://github.com/auth0/auth0-idtoken-verifier-js
		// Note: Decoding ID token on the client side is generally not recommended for production apps
		// In a production environment, the server should handle token validation and decoding
		// This is just a simplified example for educational purposes
		const base64Url = idToken.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const decoded = JSON.parse(atob(base64));
		return decoded;
	};


	const getToken = async (req, res) => {
		try {
			const code = res.params.code;
			const codeVerifier = req.codeVerifier;
			const tokenUrl = `${loginDetails.token_url}`;
			//?grant_type=authorization_code&code=${code}&redirect_uri=${redirectUri}&code_verifier=${codeVerifier}&client_id=${clientId}`;
			console.warn('tokenUrl: ', tokenUrl);
			let result = await fetch(tokenUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'Content-Security-Policy': 'upgrade-insecure-requests',
				},
				body: new URLSearchParams({
					grant_type: 'authorization_code',
					code: `${code}`,
					redirect_uri: `${redirectUri}`,
					code_verifier: `${codeVerifier}`,
					client_id: `${loginDetails.client_id}`
				}).toString(),
			});
			result = await result.json();
			//console.log(result, 'result.id_token');
			//console.log('value', result.access_token);
			let token = result.access_token;
			const idToken = result.id_token;

			// Decode the ID token to get user information (example for Auth0)
			const decodedIdToken = decodeIdToken(idToken);
			//console.log('Decoded ID Token:', decodedIdToken);

			// Extract the user's email from the decoded ID token
			const userEmail = decodedIdToken.email;
			//console.log('User Email:', userEmail);
			if (userEmail == username) {
				handleSubmitSSO(token, loginDetails.locationId);
			}
			else {
				alert("You are using wrong account to login.");
			}
			return result;
		} catch (e) {
			handleChangeInput(false, 'loading');
			console.error(e);
		}
	};

	const handleSSO = async (token) => {
		try {


			const url = `${API()}/action/sso-login/${loginDetails.locationId}`;
			const headers = new Headers();
			headers.append('Authorization', `Bearer ${token}`);
			const options = {
				url,
				method: "POST",
				headers,
				params: {
					getToken: true,
					ajax: true,
				},
			};
			const response = await fetch(url, options);
			//console.log(response, "response Data");
			if (response.ok) {
				const data = await response.json();
				//console.log(data, "SSO Data");
				login.init(data.tokens);
			}
		} catch (error) {
			console.warn(error, "handleSSO");
		}
	}

	useEffect(() => {
		const handleAuthResult = async () => {
		  try {
			if (
			  result?.type === 'success' &&
			  result?.params &&
			  result?.params.code &&
			  result?.params.state === authRequest?.state &&
			  authRequest?.codeVerifier
			) {
			  await getToken(authRequest, result);
			}
		  } catch (error) {
			console.error('Error handling authentication result:', error);
		  }
		};
	  
		handleAuthResult();
	  }, [result, authRequest]); 

	return (
		<>
			<Title title={t('logIn')} />
			<Input
				value={username}
				placeholder={t('emailAddress')}
				onChangeText={(value) => handleChangeInput(value, 'username')}
				autoCompleteType="email"
				textContentType="username"
				autoCapitalize="none"
				keyboardType="email-address"
				returnKeyType="next"
				onSubmitEditing={() => checkConnectionBeforeSending(username, password, remember)}
				error={error.username}
			/>
			{loginType == "local" &&
				<Input
					value={password}
					placeholder={t('password')}
					onChangeText={(value) => handleChangeInput(value, 'password')}
					onSubmitEditing={() => checkConnectionBeforeSending(username, password, remember)}
					autoCompleteType="password"
					autoCapitalize="none"
					returnKeyType="done"
					returnKeyLabel={t('logIn')}
					textContentType="password"
					secureTextEntry
					error={error.password}
					ref={secondInput}
				/>
			}
			<View style={styles.forgotControlContainer}>
				<TouchableOpacity
					onPress={() => history.push({
						pathname: '/',
						state: {
							page: 'forgot',
						},
					})}
				>
					<Text style={styles.forgotControl}>{t('forgotPasswordControl')}</Text>
				</TouchableOpacity>
			</View>
			{/* Remember me is removed because the client
			wanted to have a token life time of 12 to 24 hours
			after the last action */}
			{/* <LabeledSwitch
				label={t('rememberMe')}
				value={remember}
				onValueChange={(value) => handleChangeInput(value, 'remember')}
			/> */}
			<View style={styles.containerControls}>
				<View style={styles.controlLogin}>
					<Control
						loading={loading}
						title={t('logIn')}
						onPress={() => checkConnectionBeforeSending(username, password, remember)}
					/>
				</View>
			</View>
			<TouchableOpacity
				onPress={() => history.push({
					pathname: '/',
					state: {
						page: 'contact',
					},
				})}
			>
				<Text style={styles.contactControl}>{t('contactSafeBuildings')}</Text>
				<Version />
			</TouchableOpacity>
			{/* {env === 'local' && (
				<Control
					theme="alt"
					title="Clean the app"
					onPress={login.logout}
				/>
			)} */}
		</>
	);
}

const mapStateToProps = createStructuredSelector({
	loginBox: makeSelectLoginBox(),
	isConnected: makeSelectNetworkIsConnected(),
});

export function mapDispatchToProps(dispatch) {
	return {
		handleChangeInput: (value, name) => dispatch(changeInput(value, name)),
		handleSubmitForm: (username, password, remember) => dispatch(submitForm(username, password, remember)),
		handleSubmitAuth: (username) => dispatch(submitAuth(username)),
		handleSubmitSSO: (token, locationId) => dispatch(submitSSO(token, locationId)),
		handleSSOCompleted: () => dispatch(ssoCompleted()),
		handleUnmount: () => dispatch(unmount()),
		handleTryLocalAuth: () => dispatch(tryLocalAuth()),
		handleCanLocalAuth: (value) => dispatch(canLocalAuth(value)),
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(
	withConnect,
	withRouter,
	// memo,
)(LoginBox);
